import { action, computed, observable, makeObservable } from 'mobx';
import { breakPoints } from 'telia-front-react';
import { ViewPortSize } from './ViewPortSize';

export class ViewPortStore {
  @observable viewPortSize: ViewPortSize;
  @observable private viewPortWidth = 0;
  @observable private viewPortHeight = 0;

  constructor() {
    makeObservable(this);
    this.addListener();
    this.setViewPortSize();
  }

  @computed
  get isBelowExtraSmallBreakpoint(): boolean {
    return [ViewPortSize.xxs, ViewPortSize.xs].includes(this.viewPortSize);
  }

  @computed
  get isBelowSmallBreakpoint(): boolean {
    return [ViewPortSize.xxs, ViewPortSize.xs, ViewPortSize.sm].includes(this.viewPortSize);
  }

  @computed
  get isBelowMediumBreakpoint(): boolean {
    return [ViewPortSize.xxs, ViewPortSize.xs, ViewPortSize.sm, ViewPortSize.md].includes(this.viewPortSize);
  }

  @computed
  get isBelowLargeBreakpoint(): boolean {
    return [ViewPortSize.xxs, ViewPortSize.xs, ViewPortSize.sm, ViewPortSize.md, ViewPortSize.lg].includes(this.viewPortSize);
  }

  isBelowWidth = (width: number): boolean => this.viewPortWidth < width;

  isBelowHeight = (height: number): boolean => this.viewPortHeight < height;

  private addListener(): void {
    window.addEventListener('resize', this.setViewPortSize);
  }

  @action
  private setViewPortSize = (): void => {
    this.viewPortWidth = document.documentElement.clientWidth;
    this.viewPortHeight = document.documentElement.clientHeight;

    if (!this.isBelowWidth(breakPoints.xl)) {
      this.viewPortSize = ViewPortSize.xl;
    } else if (!this.isBelowWidth(breakPoints.lg)) {
      this.viewPortSize = ViewPortSize.lg;
    } else if (!this.isBelowWidth(breakPoints.md)) {
      this.viewPortSize = ViewPortSize.md;
    } else if (!this.isBelowWidth(breakPoints.sm)) {
      this.viewPortSize = ViewPortSize.sm;
    } else if (!this.isBelowWidth(breakPoints.xs)) {
      this.viewPortSize = ViewPortSize.xs;
    } else {
      this.viewPortSize = ViewPortSize.xxs;
    }
  };
}
