export enum ApiUrl {
  FEATURE_FLAGS = '/order-frontend/api/v1/feature-flags/ui',
  EXTERNAL_LINKS = '/order-frontend/api/v1/config/external-links',
  TRANSLATIONS_BASE = '/order-frontend/api/v1/translations',
  PRODUCT_USERS = '/myse-frontend/api/v1/products/product-users',
  CRM_BASE = '/sales-api/crm',
  SEEMEAPI_LASTDATA = '/order-frontend/api/v1/seeme/vehicles/getLastData/{hash}',
  WORKFORCE_WORKORDER = '/order-frontend/api/v1/workforce/technicians/work-order',
  WORKFORCE_BOOKING = '/order-frontend/api/v1/workforce/bookings/{bookingId}/hash/{hash}',
  WORKFORCE_BOOKING_HASH = '/order-frontend/api/v1/workforce/bookings/hash/{hash}',
  WORKFORCE_BOOKING_ID = '/order-frontend/api/v1/workforce/bookings/{bookingId}',
  WORKFORCE_BOOKING_ETA = '/order-frontend/api/v1/workforce/bookings/hash/{hash}/eta',
  WORKFORCE_BOOKING_CANCEL = '/order-frontend/api/v1/workforce/bookings/hash/{hash}/cancellation',
  WORKFORCE_BOOKING_PENDING = '/order-frontend/api/v1/workforce/bookings/pending',
  WORKFORCE_SCHEDULES = '/order-frontend/api/v1/workforce/schedules/hash/{hash}',
  WORKFORCE_SCHEDULES_BOOKED = '/order-frontend/api/v1/workforce/schedules/booked',
}
