import { DateUtil } from '@teliaee/sf.core';

export const nowIsAfter = (input: string | null): boolean => DateUtil.nowIsAfter(input);

export const isBeforeNow = (input: string): boolean => DateUtil.isBeforeNow(input);

export const nowIsBefore = (input: Date | undefined): boolean => DateUtil.nowIsBefore(input);

export const isBeforeOf = (date: Date | undefined): boolean => DateUtil.isBeforeOf(date);

export const isAfterNow = (input: string): boolean => DateUtil.isAfterNow(input);

export const firstIsAfterSecond = (first: Date | string, second: Date | string): boolean => DateUtil.firstIsAfterSecond(first, second);

export const toUtcTimeZone = (date: Date): Date => DateUtil.toUtcTimeZone(date);

export const formatNow = (formatStr: string): string => DateUtil.formatNow(formatStr);

export const format = (input: string | Date | undefined, formatString: string): string => DateUtil.format(input, formatString);

export const toDate = (date: Date | string | undefined): Date => DateUtil.toDate(date);

export const formatICSTimestamp = (date: Date): string => DateUtil.formatICSTimestamp(date);

export const formatDate = (date: string | Date | null | undefined): string | undefined =>
  (date && DateUtil.format(date, 'dd.MM.yyyy')) || undefined;

export const formatTime = (date: string | Date | null | undefined): string | undefined =>
  (date && DateUtil.format(date, 'HH:mm')) || undefined;

export const formatDateTime = (date: string | Date | null | undefined): string | undefined =>
  (date && DateUtil.format(date, 'dd.MM.yyyy HH:mm')) || undefined;
