import { API } from '@teliaee/sf.core';
import { ApiUrl } from '../constants/api';
import { Contact } from '../types/crm/Contact';
import { CustomerCharacteristic } from '../types/crm/CustomerCharacteristic';
import { CustomerContract, CustomerContractState } from '../types/crm/CustomerContract';
import { Person } from '../types/crm/Person';

export const getContactInfo = () => API.get<Contact>(`${ApiUrl.CRM_BASE}/contacts`).then((response) => response.data);

export const getCustomerContracts = (customerId: number, stateCode?: CustomerContractState) =>
  API.get<CustomerContract[]>(`${ApiUrl.CRM_BASE}/contracts/${customerId}`, { params: { stateCode } }).then((response) => response.data);

export const getPerson = (personalCode: string, personalCodeCountryCode: string) =>
  API.get<Person>(`${ApiUrl.CRM_BASE}/persons/personal-code/${personalCode}/country-code/${personalCodeCountryCode}`).then(
    (response) => response.data as Person
  );

export const getCustomerCharacteristics = (customerId: number) =>
  API.get<{ customerCharacteristics: CustomerCharacteristic[] }>(
    `${ApiUrl.CRM_BASE}/customer-characteristics/customers/${customerId}`
  ).then((response) => response.data);
