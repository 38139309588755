import { action, observable, makeObservable } from 'mobx';

import { IFormHelperProps, INoticeProps } from 'telia-front-react';
import { Message } from './Message';

export class MessageStore {
  @observable private messages: Map<string, Map<string, Message<IFormHelperProps | INoticeProps>>> = new Map();

  private static generateRandomKey(): string {
    return (Date.now() + Math.random()).toString(36);
  }

  constructor() {
    makeObservable(this);
  }

  @action
  addMessage<T extends IFormHelperProps | INoticeProps = INoticeProps>(locationKey: string, message: Message<T>): void {
    const key = message.key || MessageStore.generateRandomKey();
    const messages = this.messages.get(locationKey) || new Map();

    messages.set(key, new Message<T>({ ...message, key }));

    this.messages.set(locationKey, messages);
  }

  @action
  deleteMessages(locationKey: string, messageKey?: string): void {
    if (!messageKey) {
      this.messages.delete(locationKey);
    } else if (this.messages.has(locationKey)) {
      this.messages.get(locationKey)!.delete(messageKey);
    }
  }

  @action
  clear(): void {
    this.messages.clear();
  }

  getMessages<T extends IFormHelperProps | INoticeProps = INoticeProps>(locationKey: string): Array<Message<T>> {
    return this.messages.has(locationKey) ? ([...this.messages.get(locationKey)!.values()] as Array<Message<T>>) : [];
  }
}
