import { action, computed, makeObservable, observable } from 'mobx';
import SsoStore from '../sso/store';
import { Contact, PersonConsents } from '../types/crm/Contact';
import { CustomerCharacteristic } from '../types/crm/CustomerCharacteristic';
import { Person } from '../types/crm/Person';
import { Loadable } from '../utils/loadable';
import { getContactInfo, getCustomerCharacteristics, getPerson } from './api';

export default class CrmStore {
  ANONYMOUS_CONTACT: Contact = { firstName: '', lastName: '', personalCode: '', phoneNumber: '', email: '' };

  private ssoStore: SsoStore;
  @observable readonly loadableContact = new Loadable<Contact>();
  @observable readonly loadablePerson = new Loadable<Person>();
  @observable readonly loadableCustomerCharacteristics = new Loadable<CustomerCharacteristic[]>();

  constructor(rootStore: { ssoStore: SsoStore }) {
    this.ssoStore = rootStore.ssoStore;

    makeObservable(this);
  }

  fetchLoggedInPerson = async () =>
    this.loadablePerson.load(() =>
      getPerson(this.ssoStore.customer!.registrationNumber, this.ssoStore.customer!.registrationNumberCountryCode)
    );

  fetchCustomerCharacteristics = async () =>
    this.loadableCustomerCharacteristics.load(
      async () => (await getCustomerCharacteristics(this.ssoStore.customer!.customerId)).customerCharacteristics
    );

  setupContactInfo = async (): Promise<void> => {
    await this.loadableContact.load(() => getContactInfo());
    if (this.loadableContact.isFailed) {
      this.loadableContact.error!.message = 'Contact info fetch failed';
    }
  };

  @action
  reset() {
    this.loadableContact.reset();
    this.loadablePerson.reset();
    this.loadableCustomerCharacteristics.reset();
  }

  @computed
  get consents(): PersonConsents | undefined {
    return this.loadablePerson.isLoaded
      ? {
        directMarketingAllowed: this.loadablePerson.data!.directMarketingAllowed,
        trafficDataAnalysisAllowed: this.loadablePerson.data!.trafficDataAnalysisAllowed,
        locationDataAnalysisAllowed: this.loadablePerson.data!.locationDataAnalysisAllowed,
      }
      : undefined;
  }

  @computed
  get contact() {
    return this.loadableContact.isLoaded ? this.loadableContact.data : this.ANONYMOUS_CONTACT;
  }

  @computed
  get customerCharacteristicLoaded(): boolean {
    return this.loadableCustomerCharacteristics.isLoaded;
  }

  get isEmployee(): boolean {
    return this.customerCharacteristicContains('EMPLOYEE');
  }

  private customerCharacteristicContains(characteristicName: 'EMPLOYEE') {
    if (!this.customerCharacteristicLoaded || !this.loadableCustomerCharacteristics.data) {
      return false;
    }
    return this.loadableCustomerCharacteristics.data.some(({ name }: CustomerCharacteristic) => name === characteristicName);
  }

  @computed
  get hasCustomerData(): boolean {
    return !!this.loadableContact.data;
  }
}
