import React from 'react';
import { Button } from 'telia-front-react';
import { format } from '../../../../../../../common/utils/date';

import { IProps } from './IProps';

const Selectable = (props: IProps) => {
  const { timeRange, selectedTimeRange, onClick } = props;

  return timeRange === selectedTimeRange ? (
    <Button
      type="expressive"
      text={format(timeRange.from, 'HH:mm')}
      size="small"
      iconRight="#arrow-right"
      className="myTechnician__btn-margin"
    />
  ) : (
    <Button
      text={format(timeRange.from, 'HH:mm')}
      size="small"
      variant="secondary"
      iconRight="#arrow-right"
      className="myTechnician__btn-margin"
      onClick={onClick}
    />
  );
};

export default Selectable;
