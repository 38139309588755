import React from 'react';
import { DASHBOARD } from '../../../constants/links';
import { Card, CardContent, Container, Grid, GridColumn, Icon, Separator, Text, AnalyticsProvider, Button } from 'telia-front-react';
import IProps from './IProps';

const ErrorView = (props: IProps) => {
  const { titleHtml, titleMessageHtml, button1Text, button2Text } = props;
  const reload = (): void => {
    window.location.reload();
  };

  const navigateToHome = (): void => {
    window.location.replace(DASHBOARD);
  };

  return (
    <AnalyticsProvider page={'Error'} group="ERROR">
      <Container>
        <Separator spacing="small" color="invisible" />
        <Text className="text-center">
          <span className="text-error">
            <Icon modifiers="#alert" size="xxxl" />
          </span>
          <h1 dangerouslySetInnerHTML={titleHtml} />
          <p className="h4" dangerouslySetInnerHTML={titleMessageHtml} />
        </Text>
        <Separator spacing="small" color="invisible" />
        <Card padding="medium">
          <CardContent>
            <Grid align={['between-sm', 'middle-xs']}>
              <GridColumn width={['xs-12', 'sm']} order={['last-sm']}>
                <div className="text-center">
                  <Button id="technical-error-retry" onClick={reload} text={button1Text} />
                </div>
              </GridColumn>
              <GridColumn width={['xs-12', 'sm']}>
                <div className="text-center">
                  <Button id="technical-error-back-to-home" link iconLeft="#arrow-left" onClick={navigateToHome} text={button2Text} />
                </div>
              </GridColumn>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </AnalyticsProvider>
  );
};

export default ErrorView;
