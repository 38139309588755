import { action, observable, makeObservable } from 'mobx';
import { IConsent } from 'telia-front-react';
import isequal from 'lodash.isequal';

export interface CookieConsents {
  statistics?: boolean;
  marketing?: boolean;
  necessary?: boolean;
  preferences?: boolean;
}

export class CookieConsentStore {
  @observable public isCookiebotEnabled: boolean;
  @observable public cookieConsents: IConsent = {
    statistics: false,
    marketing: false,
    necessary: false,
    preferences: false,
  };

  constructor() {
    makeObservable(this);
    this.evaluateCookiebotEnabled();
    this.setListeners();
    /* istanbul ignore next */
    if (this.isCookiebotEnabled) {
      this.setCookieConsents();
    }
  }

  @action
  public evaluateCookiebotEnabled = () => {
    /* istanbul ignore next */
    const isCookiebotLoaded = !!window.CookieControl?.Dialog || window.Cookiebot?.consented || window.Cookiebot?.declined;
    this.isCookiebotEnabled = isCookiebotLoaded || this.isCookiebotBlocked();
  };

  private isCookiebotBlocked = () => !!window.Cookiebot && !window.CookieControl;

  private setListeners = () => {
    window.addEventListener('CookiebotOnLoad', () => {
      this.evaluateCookiebotEnabled();
      this.setCookieConsents();
    });
    window.addEventListener('CookiebotOnDialogInit', () => this.evaluateCookiebotEnabled());
  };

  @action
  private setCookieConsents = () => {
    /* istanbul ignore next */
    const cookieConsents = window.Cookiebot?.consent || { necessary: true };
    this.changePlumbrPermissions(cookieConsents);
    if (!this.cookieConsents || !isequal(this.cookieConsents, cookieConsents)) {
      this.cookieConsents = cookieConsents;
    }
  };

  private changePlumbrPermissions(cookieConsents: CookieConsents) {
    if (window.PLUMBR) {
      window.PLUMBR.setCookiePermissions({ user: cookieConsents.statistics, session: cookieConsents.statistics });
    }
  }
}
