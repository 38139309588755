import React from 'react';
import { observer } from 'mobx-react';
import { Grid, GridColumn, Text } from 'telia-front-react';
import TimeRange from '../../../../../common/components/time/range';
import IProps from './IProps';

const Past = observer((props: IProps) => {
  const { bookingTimeRange, booking, translate } = props;
  return (
    <Grid align={['middle-sm']}>
      <GridColumn width={['md-3']}>
        <div>
          <Text className="text">{translate('myTechnician.feedback.visit')}</Text>
          <TimeRange translate={translate} timeRange={bookingTimeRange} />
        </div>
      </GridColumn>
      <GridColumn width={['md-4']} />
      <GridColumn width={['md-5']}>
        <Grid align={['end-sm']}>
          <GridColumn width={['xs-12', 'md-9']}>
            <Text>{translate('myTechnician.feedback.technician')}</Text>
            <Text className="text-bold">{booking && booking.employeeFirstName}</Text>
          </GridColumn>
        </Grid>
      </GridColumn>
    </Grid>
  );
});

export default Past;
