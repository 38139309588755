import { CoreConfig } from '@teliaee/sf.core';
import { BasePostMessage } from './model';

export const sendPostMessageToWebView = (message: BasePostMessage): void => {
  sendToWebView(message);
};

export const sendToWebView = (data: NonNullable<any>): void => {
  if (!CoreConfig.isWebview) {
    return;
  }
  // most unlikely to happen, but just-in-case to warn of silent no-ops
  if (typeof window['ReactNativeWebView'] === 'undefined') {
    console.error(
      'Attempted to access undefined window.ReactNativeWebView ' +
        'in webview context. Did webview have enough time to inject ' +
        'ReactNativeWebView into window?'
    );
    return;
  }
  // window.ReactNativeWebView.postMessage only accepts one argument which must be a string.
  const stringified = stringify(data);
  window['ReactNativeWebView'].postMessage(stringified);
};

const stringify = (value: any): string => JSON.stringify(value);
