import { isApiResponseWithError, makeRequest } from '@teliaee/sf.core';
import { ApiUrl } from '../constants/api';
import { FeatureFlag } from './types';

export const readFeatureFlags = () =>
  makeRequest<FeatureFlag[]>({
    method: 'get',
    url: ApiUrl.FEATURE_FLAGS,
    transformResponse: (responseBody) => (isApiResponseWithError(responseBody) ? responseBody : { data: responseBody }),
  });
