import React from 'react';
import { Button, Grid, Separator, Text } from 'telia-front-react';
import IProps from './IProps';
import Preparation from './components/preparation';

const Attendance = (props: IProps) => {
  const { title, bookingType, openSsoLogin, translate } = props;

  return (
    <React.Fragment>
      <Text className="h2 text-center">{title}</Text>

      <Separator spacing="small" color="invisible" />

      <Grid equalHeight align={['middle-sm']} id="myTechnician-attendance-grid">
        <Preparation
          icon="#time-round"
          title={translate('mytechnician.visit_info.arrival.header')}
          description={translate('mytechnician.visit_info.arrival.content')}
        />
        <Preparation
          icon="#contact"
          title={translate('mytechnician.visit_info.attendance.header')}
          description={translate('mytechnician.visit_info.attendance.content2')}
        >
          <Button
            text={translate('mytechnician.visit_info.attendance.content.linkname')}
            link
            onClick={openSsoLogin}
            id="myTechnician-button-open-sso-login"
          />
        </Preparation>
        {bookingType === 'DELIVERY' ? (
          <React.Fragment>
            <Preparation
              icon="#technician"
              title={translate('mytechnician.visit_info.drilling.header')}
              description={translate('mytechnician.visit_info.drilling.content')}
            />
            <Preparation
              icon="#computer"
              title={translate('mytechnician.visit_info.device_location.header')}
              description={translate('mytechnician.visit_info.device_location.content')}
            />
          </React.Fragment>
        ) : (
          ''
        )}
        <Preparation
          icon="#parcel-machine"
          title={translate('mytechnician.visit_info.breakables.header')}
          description={translate('mytechnician.visit_info.breakables.content')}
        />
        <Preparation
          icon="#facemask"
          title={translate('mytechnician.visit_info.health.header')}
          description={translate('mytechnician.visit_info.health.content')}
        />
      </Grid>
    </React.Fragment>
  );
};

export default Attendance;
