import { observer } from 'mobx-react';
import React from 'react';
import { Button, DateField, IDatepickerProps, Grid, GridColumn, Separator, Text } from 'telia-front-react';
import Selectable from './components/selectable';
import IProps from './IProps';

const Change = observer((props: IProps) => {
  const {
    translate,
    availableDates,
    selectedTime,
    selectedTimeRange,
    moreTimesAvailable,
    handleDateSelect,
    handleTimeSelect,
    handleShowMoreTimes,
    handleSaveBookingTime,
    handleCloseChangeTime,
    selectableTimeRanges,
  } = props;

  const datepickerProps: IDatepickerProps = {
    columns: ['year', 'month', 'calendar'],
    onSubmit: handleDateSelect,
    clearButtonLabel: translate('mytechnician.change_time.datepicker.clear'),
    submitButtonLabel: translate('mytechnician.change_time.datepicker.submit'),
  };

  return (
    <React.Fragment>
      <Grid align={['middle-sm']}>
        <GridColumn width={['sm-8']}>
          <DateField
            id="myTechnician-date-field"
            format="dd.MM.y"
            times={availableDates || undefined}
            defaultValue={selectedTime}
            datepicker={datepickerProps}
            label={translate('mytechnician.change_time.select_date')}
          />
          <Separator spacing="small" color="invisible" />
          <Text>{translate('mytechnician.change_time.select_time')}</Text>
          {selectableTimeRanges &&
            selectableTimeRanges.map((timeRange, index) => (
              <Selectable
                key={`selectable-time-${timeRange.from.valueOf()}-${index}`}
                timeRange={timeRange}
                selectedTimeRange={selectedTimeRange}
                onClick={() => handleTimeSelect(timeRange)}
              />
            ))}
          {moreTimesAvailable && (
            <React.Fragment>
              <Separator spacing="small" color="invisible" />
              <Button
                id="myTechnician-button-show-more-times"
                text={translate('myTechnician.change_time.show_all')}
                iconRight="#plus"
                size="small"
                onClick={() => handleShowMoreTimes()}
              />
            </React.Fragment>
          )}
        </GridColumn>
        <GridColumn width={['sm-3']}>
          <Button
            id="myTechnician-button-save-booking"
            className="myTechnician__btn-margin btn--block"
            text={translate('mytechnician.change_time.save')}
            type="expressive"
            iconRight="#arrow-right"
            onClick={() => handleSaveBookingTime()}
          />
          <Button
            id="myTechnician-button-close-change-time"
            className="myTechnician__btn-margin btn--block"
            text={translate('mytechnician.change_time.cancel')}
            type="withdrawal"
            variant="secondary"
            onClick={() => handleCloseChangeTime()}
          />
        </GridColumn>
      </Grid>
    </React.Fragment>
  );
});

export default Change;
