import Location from './Location';
import { observable, makeObservable } from 'mobx';

export default class TechnicianBooking {
  bookingId: Number;
  clientName: string;
  startTime: string;
  endTime: string;
  employeeFirstName: string;
  seeMeApiHash: string;
  location: Location;
  language: string;
  bookingType: string;
  @observable eta: number | null;
  bookingState: string;

  static fromJson(bookingJson: TechnicianBooking): TechnicianBooking {
    return Object.assign(new TechnicianBooking(), bookingJson);
  }

  constructor() {
    makeObservable(this);
  }
}
