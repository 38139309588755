import { computed, observable, reaction, makeObservable, action, IReactionDisposer } from 'mobx';
import ProductUserCompact from '../../types/ProductUserCompact';

import { readProductUsers } from './api';
import { ApiResponse, isApiResponseWithError, isCancelledResponse } from '@teliaee/sf.core';
import { RootStore } from '../../root/init';
import { ProductRef } from '../../../common/types/product/ProductRef';

export default class ProductUserStore {
  protected reactionDisposers: IReactionDisposer[] = [];
  @observable.ref protected productUsersResponse: ApiResponse<any> | undefined;
  @observable isLoading = false;
  protected cancelProductUsersRequest = () => {};

  constructor(protected readonly context: RootStore) {
    makeObservable(this);
  }

  async init(): Promise<void> {
    this.reactionDisposers.push(
      reaction(
        () => this.context.ssoStore.person,
        () => this.updatePersonProductUsers()
      )
    );
    await this.updatePersonProductUsers();
  }

  @computed
  get isErrored(): boolean {
    return isApiResponseWithError(this.productUsersResponse);
  }

  @action
  protected setProductUserResponse(response: ApiResponse<any>) {
    this.productUsersResponse = response;
  }

  @computed
  get hasLoadedPersonProductUsers() {
    return this.productUsersResponse !== undefined;
  }

  @computed
  get displayableProductUsers(): ProductUserCompact[] {
    return this.personProductUsers.concat(this.customerProductUsers);
  }

  @computed
  get hasDisplayableProductUsers(): boolean {
    return !!this.displayableProductUsers.length;
  }

  @computed
  get hasPersonProductUsers(): boolean {
    return !!this.personProductUsers.length;
  }

  @computed
  get hasCustomerProductUsers(): boolean {
    return !!this.customerProductUsers.length;
  }

  @computed
  private get personProductUsers(): ProductUserCompact[] {
    const { customerId } = this.context.ssoStore;
    const { hasLegalOrServiceMandate } = this.context.mandateCheckerStore;
    return hasLegalOrServiceMandate ? [] : this.currentProductUsers.filter((productUser) => productUser.customerId === customerId);
  }

  @computed
  private get customerProductUsers(): ProductUserCompact[] {
    const { customerId, isCustomerSameAsPerson } = this.context.ssoStore;
    return isCustomerSameAsPerson ? this.currentProductUsers.filter((productUser) => productUser.customerId !== customerId) : [];
  }

  @computed
  get currentProductUsers(): ProductUserCompact[] {
    return this.productUsersResponse?.data;
  }

  protected setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  updatePersonProductUsers = async (): Promise<void> => {
    this.productUsersResponse = undefined;
    if (!this.context.ssoStore.person) return;

    const { cancelRequest, responsePromise } = readProductUsers();
    this.cancelProductUsersRequest = cancelRequest;
    this.setIsLoading(true);

    const { person } = this.context.ssoStore;
    if (!person || !person.personalCode || !person.personalCodeCountryCode) return;

    const response = await responsePromise;
    this.setIsLoading(false);

    if (!isCancelledResponse(response)) {
      this.setProductUserResponse(response);
    }
  };

  getProductUser(productRef: ProductRef): ProductUserCompact | undefined {
    return this.currentProductUsers.find(
      (productUser) => productUser.productId === productRef.productId && productUser.origin === productRef.origin
    );
  }
}
