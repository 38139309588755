import React from 'react';
import { GridColumn, Icon, Text } from 'telia-front-react';
import IProps from './IProps';

const Preparation = (props: IProps) => {
  const { icon, title, description, children } = props;

  return (
    <GridColumn width={['sm-6']}>
      <div>
        <Icon modifiers={icon} size="xl" className="myTechnician__preparation-icon" />
      </div>
      <div className="myTechnician__preparation-info">
        <Text className="h3">{title}</Text>
        <div>{description}</div>
        <div>{children}</div>
      </div>
    </GridColumn>
  );
};

export default Preparation;
