import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import RouteKey from './common/constants/keys';
import { AnalyticsProvider } from 'telia-front-react';
import { Analytics } from '@teliaee/sf.core';
import Required from './common/components/customer';
import { FeatureStore } from './common/features';
import { DASHBOARD } from './common/constants/links';

export interface ProtectedRouteProps extends RouteProps {
  rootStore?: {
    featureStore: FeatureStore;
  };
  routeKey: RouteKey;
  feature?: string;
  render: (props: RouteComponentProps) => React.ReactChild;
  children?: null;
}

export interface RouteContextType {
  routeKey: RouteKey;
}

export const RouteContext = React.createContext<RouteContextType>({
  routeKey: RouteKey.UNDEFINED,
});

@inject('rootStore')
@observer
export default class ProtectedRoute extends React.Component<ProtectedRouteProps> {
  render(): React.ReactNode {
    const { rootStore, routeKey } = this.props;

    if (!rootStore!.featureStore.initialized) {
      return null;
    }

    if (this.isRouteEnabled()) {
      return (
        <AnalyticsProvider event={Analytics.defaultEvent} page={routeKey} onPush={Analytics.push}>
          <RouteContext.Provider
            value={{
              routeKey,
            }}
          >
            <Required>
              <Route {...this.props} />
            </Required>
          </RouteContext.Provider>
        </AnalyticsProvider>
      );
    }

    return <Redirect to={DASHBOARD} />;
  }

  private isRouteEnabled(): boolean {
    const { feature, rootStore } = this.props;

    return !feature || rootStore!.featureStore.isEnabled(feature);
  }
}
