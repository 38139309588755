export enum RouteKey {
  FINISH_BASKET = 'finishBasket',
  SMART_WIFI = 'smartWifi',
  SMART_WIFI_BUSINESS = 'smartWifiBusiness',
  SECURE_INTERNET = 'secureInternet',
  DEVICE_EXCHANGE = 'deviceExchange',
  DEVICE_HANDOUT = 'deviceHandout',
  DEVICE_RETURN = 'deviceReturn',
  SIM_HANDOUT = 'simHandout',
  TV = 'tvOrder',
  MULTI_CLICK_TV_SCREENS = 'multiClickTvScreens',
  MULTI_CLICK_TV_LIMITS = 'multiClickTvLimits',
  MULTI_CLICK_TV_RECORDING = 'multiClickTvRecording',
  MULTI_CLICK_TV_TELIA1 = 'multiClickTvTelia1',
  MULTI_CLICK_TV_SERVICES = 'multiClickTvServices',
  MULTI_CLICK_TV_PACKAGES = 'multiClickTvPackages',
  MULTI_CLICK_MOBILE_EXTRA = 'multiClickMobileExtra',
  MULTI_CLICK_MOBILE_MONTHLY_EXTRA = 'multiClickMobileMonthlyExtra',
  MULTI_CLICK_MOBILE_EXTRA_CALLS = 'multiClickMobileExtraCalls',
  MULTI_CLICK_MOBILE_AUTO_EXTRA = 'multiClickMobileAutoExtra',
  MOBILE_FORWARDING = 'mobileForwarding',
  MULTI_CLICK_MOBILE_DATA_LIMIT = 'multiClickMobileDataLimit',
  MULTI_CLICK_TV_CHANNELS = 'multiClickTvChannels',
  MULTI_CLICK_SAFE3_EMAIL = 'multiClickSafe3Email',
  MULTI_CLICK_STATIC_IP = 'multiClickStaticIp',
  MULTI_CLICK_PORTS = 'multiClickPorts',
  MULTI_CLICK_PRIVATE_BILL_PAYMENT = 'multiClickPrivateBillPayment',
  MULTI_CLICK_INTERNET_SPEED = 'multiClickInternetSpeed',
  MULTI_CLICK_INTERNET_SPEED_CONFIRM = 'multiClickInternetSpeedConfirm',
  BUSINESS_TV = 'businessTvOrder',
  INFO_TV = 'infoTvOrder',
  DELETE_DEVICE = 'deviceTermination',
  DELETE_PRODUCT_CANCELLED_BY_FREE_SERVICE = 'deviceTerminationCancelled',
  PRESALE_LANDING_THANK_YOU_PAGE = 'presaleLandingThankYouPage',
  TV_DEVICE = 'tvDeviceOrder',
  INTERNET = 'internetOrder',
  INTERNET_DEVICE = 'internetDeviceOrder',
  INTERNET_MOVE = 'internetMoveOrder',
  SPOTIFY = 'spotifyOrder',
  HOME_PRODUCTS = 'homeServices',
  OFFICE_PRODUCTS = 'officeServices',
  VAS_PRODUCTS = 'vasServices',
  MOBILE_PRODUCT = 'mobileService',
  MOBILE_PRODUCTS = 'mobileServices',
  MOBILE_ADDITIONAL_SERVICES = 'mobileAdditionalServices',
  CONFIRM_DOCUMENTS = 'confirmDocuments',
  CANCEL_ORDER = 'orderCancel',
  CANCEL_CHANGE_CUSTOMER = 'cancelChangeCustomer',
  CHANGE_PRODUCT = 'changeAccount',
  CHANGE_CUSTOMER = 'changeCustomer',
  DELETE_PRODUCT = 'deleteProduct',
  SUSPEND_PRODUCT = 'suspendProduct',
  ADD_COUPON = 'addCoupon',
  TECHNICIAN = 'myTechnician',
  NOT_FOUND = 'notFound',
  UNDEFINED = 'undefinedRoute',
  DASHBOARD = 'dashboard',
  SUPPORT = 'support',
  SUPPORT_AND_ORDERS = 'supportAndOrders',
  ERROR = 'error',
  TELIA1 = 'telia1',
  TELIA1_SUMMARY = 'telia1Summary',
  CUSTOMER_ORDER_DETAILS = 'customerOrderDetails',
  SAFE3 = 'teliaSafe',
  MULTI_CLICK_SAFE3_PACKAGES = 'multiClickSafe3Packages',
  MICROSOFT_365 = 'microsoft365',
  DYNAMIC = 'dynamic',
  TELIA_PLAY = 'TELIA_PLAY',
  TELIA_PLAY_POC = 'TELIA_PLAY_POC',
  SMART_CONNECT = 'SMART_CONNECT',
  HBO_MAX_ACTIVATION = 'hboMaxActivation',
  TELIA_PICK_AND_MIX = 'TELIA_PICK_AND_MIX',
  TELIA_PICK_AND_MIX_THANK_YOU = 'TELIA_PICK_AND_MIX_THANK_YOU',
}

export default RouteKey;
