export enum Origin {
  ORDERAPI = 'ORDERAPI',
  ARGOS = 'ARGOS',
  ARGOS_SERV = 'ARGOS_SERV',
  TBCIS = 'TBCIS',
  TBCIS_MAAS = 'TBCIS_MAAS',
  TBCIS_SERV = 'TBCIS_SERV',
}

export function isOrigin(value: string | Origin): value is Origin {
  return Object.values(Origin).some((origin) => origin === value);
}
