// @ts-nocheck

import React from 'react';
import { action, autorun, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import proj4 from 'proj4';
import { isOnScreen, scrollToElement, Text } from 'telia-front-react';
import { MyTechnicianStore } from '../../store';
import * as technicianStaticValues from '../../../../common/constants/app';
import IProps from './IProps';
import { PlaceholderMapping } from '@teliaee/sf.core';

interface CustomMapProps {
  technicianPosition?: google.maps.LatLngLiteral;
  clientAddress: string;
  infoLabel?: string;
  mapEnabled?: Boolean;
  myTechnicianStore?: MyTechnicianStore;
  clientPosition: google.maps.LatLngLiteral;
  setClientPosition: (position: google.maps.LatLngLiteral) => void;
  translateHtml: (
    key: string,
    params?: PlaceholderMapping
  ) => {
    __html: string;
  };
  eta?: number | null;
}

const googleMapsApiKey = 'AIzaSyDgIGjt91QP5bteMlptDLRj-PX9p9No3jA';

@observer
export class GoogleMapWithoutDOM extends React.Component<CustomMapProps> {
  public map: any;
  constructor(props: CustomMapProps) {
    super(props);
    this.setClientPositionIfAbsent();
  }

  setMap = (element: any) => (this.map = element);

  componentDidMount() {
    autorun(() => {
      /* istanbul ignore next */
      if (this.map && this.bounds) {
        this.map.fitBounds(this.bounds);
      }
    });
  }

  get bounds() {
    /* istanbul ignore next */
    return this.props.clientPosition && this.props.technicianPosition
      ? {
        north: Math.max(this.props.clientPosition.lat, this.props.technicianPosition.lat),
        south: Math.min(this.props.clientPosition.lat, this.props.technicianPosition.lat),
        west: Math.min(this.props.clientPosition.lng, this.props.technicianPosition.lng),
        east: Math.max(this.props.clientPosition.lng, this.props.technicianPosition.lng),
      }
      : null;
  }

  get center() {
    return this.props.clientPosition ? this.props.clientPosition : technicianStaticValues.DEFAULT_MAP_CENTER_COORDINATES;
  }

  get zoom() {
    return this.props.clientPosition ? 15 : 6;
  }

  private setClientPositionIfAbsent() {
    if (this.props.clientPosition || !this.props.clientAddress) {
      return;
    }
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: this.props.clientAddress }, (results, status) => {
      if (results && status === google.maps.GeocoderStatus.OK) {
        this.props.setClientPosition({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      }
    });
  }

  render(): JSX.Element {
    const { technicianPosition, infoLabel, mapEnabled, translateHtml, eta, clientPosition } = this.props;
    const gestureHandling = mapEnabled ? 'auto' : 'none';
    return (
      <GoogleMap
        ref={this.setMap}
        defaultOptions={{
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
          gestureHandling,
        }}
        defaultCenter={this.center}
        defaultZoom={this.zoom}
      >
        {clientPosition && (
          <Marker
            position={clientPosition}
            icon={{
              path: technicianStaticValues.MAP_CLIENT_LOGO_PATH,
              fillColor: '#0c6',
              fillOpacity: 1,
              strokeWeight: 0,
              anchor: new google.maps.Point(16, 16),
            }}
          />
        )}
        {technicianPosition && (
          <Marker
            position={technicianPosition}
            icon={{
              path: technicianStaticValues.MAP_TECHNICIAN_LOGO_PATH,
              scale: 1.5,
              fillColor: '#990ae3',
              fillOpacity: 1,
              strokeWeight: 0,
              anchor: new google.maps.Point(11, 23),
            }}
          />
        )}

        {infoLabel && eta !== null && (
          <div className="text-center">
            <span className="myTechnician__map-label" dangerouslySetInnerHTML={translateHtml(infoLabel, { visitCount: eta })} />
          </div>
        )}
      </GoogleMap>
    );
  }
}

const GoogleMapWithDOM = withScriptjs(withGoogleMap(GoogleMapWithoutDOM));

@observer
class Map extends React.Component<IProps> {
  private readonly MAP_CONTAINER_ID = 'myTechnician-map-container';
  private clientAddress: any;
  @observable mapHeight = '400px';

  constructor(props: IProps) {
    super(props);
    makeObservable(this);
    proj4.defs(
      'EPSG:3301',
      '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
    );
    const booking = props.booking;
    if (booking && booking.location && booking.location.coordX && booking.location.coordY) {
      const latLng = proj4('EPSG:3301', 'WGS84', [Number(booking.location.coordX) / 100, Number(booking.location.coordY) / 100]);
      props.setClientPosition({
        lat: latLng[1],
        lng: latLng[0],
      });
    } else if (booking && booking.location) {
      this.initClientAddress(booking.location);
    }
  }

  private initClientAddress(location: any) {
    if (location.addrString) {
      this.clientAddress = location.addrString;
    } else {
      this.clientAddress = [
        location.streetBgName,
        location.bgNum,
        location.municipality,
        location.cityCounty,
        location.postalCode,
        location.region,
      ]
        .filter((e) => e != null)
        .join(' ');
    }
  }

  componentDidMount() {
    this.doScrollingAndSetHeight();
  }

  @action
  private doScrollingAndSetHeight(): void {
    if (Math.max(document.documentElement.clientWidth, window.innerWidth) < 600) {
      scrollToElement(this.MAP_CONTAINER_ID, 500, 100);

      const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      if (viewportHeight > 200) {
        this.mapHeight = viewportHeight - 100 + 'px';
      }
    } else if (!isOnScreen(this.MAP_CONTAINER_ID)) {
      scrollToElement(this.MAP_CONTAINER_ID);
    }
  }

  render(): React.ReactNode {
    const { mapEnabled, technicianPosition, booking, etaTranslationKey, clientPosition, setClientPosition, translate, translateHtml } =
      this.props;
    return (
      booking && (
        <div id={this.MAP_CONTAINER_ID} style={{ height: this.mapHeight }} className={!mapEnabled ? 'myTechnician__map-disabled' : ''}>
          <GoogleMapWithDOM
            infoLabel={etaTranslationKey}
            googleMapURL={'https://maps.googleapis.com/maps/api/js?key=' + googleMapsApiKey + '&v=3.exp'}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div className="myTechnician__map-container" style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            technicianPosition={technicianPosition}
            clientAddress={this.clientAddress}
            mapEnabled={mapEnabled}
            eta={booking.eta}
            clientPosition={clientPosition}
            setClientPosition={(location: google.maps.LatLngLiteral) => {
              setClientPosition(location);
            }}
            translateHtml={translateHtml}
          />
          {!mapEnabled && <Text className="h3 text-center text-white">{translate('myTechnician.map.disabled')}</Text>}
        </div>
      )
    );
  }
}

export default Map;
