import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Analytics, API, CoreConfig, ErrorUtil } from '@teliaee/sf.core';
import * as mobx from 'mobx';

import './polyfills';

window.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
  error = error || new Error(event instanceof Event ? event.type : event);
  ErrorUtil.pushError(error);
  return true;
};

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  event.preventDefault();
  const error = event.reason instanceof Error ? event.reason : new Error(event.reason);
  ErrorUtil.pushError(error);
});

const doNotLoadPageFromCache = (event: PageTransitionEvent) => {
  if (event.persisted) {
    window.location.reload();
  }
};
window.addEventListener('pageshow', doNotLoadPageFromCache);

CoreConfig.init({
  environment: window.INITIAL_CONFIG.environment,
  context: navigator.userAgent.toLowerCase().includes('webview') ? 'webview' : 'browser',
});
CoreConfig.currentLocale = window.INITIAL_CONFIG.locale;
API.defaults.baseURL = '/';
Analytics.init(CoreConfig.environment);

mobx.configure({ enforceActions: 'observed', useProxies: 'never' });

if (window.INITIAL_CONFIG.grafanaDaas) {
  const script = document.createElement('script');
  script.setAttribute('src', window.INITIAL_CONFIG.grafanaDaas.rumClientUrl);
  script.onload = () => {
    window.TeliaRum.init({
      beaconUrl: window.INITIAL_CONFIG.grafanaDaas.beaconUrl,
      clientId: 'my-technician',
      clientToken: window.INITIAL_CONFIG.grafanaDaas.clientToken,
      ignoreUrls: [
        /https:\/\/cat.telia.ee\/.*/,
        /https:\/\/ccchat.estpak.ee\/.*/,
        /https:\/\/www.facebook.com\/.*/,
        /https:\/\/www.google-analytics.com\/.*/,
        /\.*.cookiebot.com\.*/,
      ],
      propagateCorsUrls: [/https:\/\/sso(-arendus|-dev2|-test)?.telia.ee(\/.*)?/, /https:\/\/www(-dev|-test)?\.telia\.ee(?!\/abi)(\/.*)?/],
      domain: '.telia.ee',
    });
  };
  document.head.appendChild(script);
}

ReactDOM.render(<App />, document.getElementById('container'));
