import { makeObservable, observable, action } from 'mobx';
import { THeaderOpenSection } from 'telia-front-react';

class MegamenuStore {
  static NOTIFICATION_SECTION = 'notifications';

  @observable openSection: THeaderOpenSection = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public openNotificationsSection() {
    this.setOpenSection(MegamenuStore.NOTIFICATION_SECTION);
  }

  @action
  public setOpenSection(section: THeaderOpenSection) {
    this.openSection = section;
  }
}

export default MegamenuStore;
