import { action, computed, IObservableArray, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { parse } from 'qs';
import { DatepickerValue, doScrolling, IDateRange, INoticeProps } from 'telia-front-react';

import { WorkforceApi, SeeMeApi } from './api';
import Booking from '../../common/types/booking/Booking';
import Technician from '../../common/types/booking/Technician';
import TechnicianBooking from '../../common/types/booking/TechnicianBooking';
import TechnicianBookingHash from '../../common/types/booking/TechnicianBookingHash';
import Schedules from '../../common/types/booking/Schedules';
import ScheduleItem from '../../common/types/booking/ScheduleItem';
import VehicleLocation from '../../common/types/booking/VehicleLocation';
import Location from '../../common/types/booking/Location';
import NavigationStore from '../../common/navigation/store';
import { BOOKING_STATE_ACTIVE, REDIRECT_TIME_MS } from '../../common/constants/app';
import { TimeRange } from '../../common/types/booking/TimeRange';
import { convertScheduleItemToTimeRange, convertTimeRangeToScheduleItem } from './utils';
import { RootStore } from '../../common/root/init';
import { nowIsBefore, formatICSTimestamp, toUtcTimeZone, toDate } from '../../common/utils/date';
import { endOfToday, format, isBefore, isSameDay, isToday, startOfDay } from 'date-fns';
import { Message } from '../../common/message';

export class MyTechnicianStore {
  private locationIntervalId: number;
  private updatedBooking: TechnicianBookingHash | null;
  private ssoReactions: IReactionDisposer[] = [];

  @observable booking: TechnicianBooking | null;
  @observable showMap: boolean;
  @observable showChangeTime: boolean;
  @observable timeRanges: IObservableArray<TimeRange>;
  @observable selectedTime: Date;
  @observable selectableTimeRanges: IObservableArray<TimeRange>;
  @observable selectedTimeRange: TimeRange | null;
  @observable bookingTimeRange: TimeRange;
  @observable notice: Message<INoticeProps> | null;
  @observable allowChangeTime: boolean;
  @observable technicianLocation: VehicleLocation | undefined;
  @observable moreTimesAvailable: boolean;
  @observable clientPosition: google.maps.LatLngLiteral;
  @observable technician: Technician;
  @observable showCancellationModal: boolean;
  @observable showCancelledModal: boolean;
  @observable cancellationReason: string;
  @observable isCancelled: boolean;
  @observable isEmployee: boolean;
  @observable isCardLoading: boolean;

  constructor(protected readonly context: RootStore) {
    makeObservable(this);
    this.showMap = false;
    this.showChangeTime = false;
    this.allowChangeTime = false;
    this.moreTimesAvailable = false;
    this.timeRanges = observable.array<TimeRange>();
  }

  async initialize() {
    await this.fetchTechnicianBooking(this.hash as string);
  }

  @action
  toggleShowMap(): void {
    this.showMap = !this.showMap;
    this.clearLocationInterval();
    if (!this.showMap) {
      doScrolling(0, 500);
    } else if (this.mapEnabled) {
      this.fetchTechnicianPosition();
      this.locationIntervalId = window.setInterval(this.fetchTechnicianPosition, 30 * 1000);
    }
  }

  openChangeTime(): void {
    if (this.allowChangeTime) {
      this.fetchSchedules();
    } else {
      this.addNotice('myTechnician.booking.cannot_change_today');
    }
  }

  @action
  closeChangeTime(): void {
    this.showChangeTime = false;
  }

  @action
  handleBookingConfirmed = (newBooking: Booking): void => {
    this.booking!.bookingId = newBooking.bookingId;
    this.setBookingTimeRange(this.selectedTimeRange!);
    this.showChangeTime = false;
    this.addNotice('myTechnician.booking.confirmed', 'success');
    this.isCardLoading = false;
  };

  handleBookingConfirmedError = (): void => {
    this.addNotice('myTechnician.booking.not_available', 'error');
    this.isCardLoading = false;
  };

  saveBookingTime() {
    const { localeStore } = this.context;
    if (!this.selectedTimeRange || !this.updatedBooking) {
      this.addNotice('myTechnician.booking.time_not_selected', 'error');
      return;
    }

    this.isCardLoading = true;

    const language: string = localeStore.currentLocale;
    const scheduleItem: ScheduleItem = convertTimeRangeToScheduleItem(this.selectedTimeRange);
    WorkforceApi.confirmBookingByHash(
      this.updatedBooking.bookingUpdateHash,
      scheduleItem,
      language,
      this.booking!.location,
      this.updatedBooking.productSpecIds,
      this.updatedBooking.bookingId,
      this.updatedBooking.argosId,
      this.updatedBooking.bookingIdType,
      this.updatedBooking.inCustomerPromise
    )
      .then((response) => this.handleBookingConfirmed(response))
      .catch(() => this.handleBookingConfirmedError());
  }

  @computed
  get bookingActive(): Boolean {
    return this.booking?.bookingState === BOOKING_STATE_ACTIVE && nowIsBefore(this.bookingTimeRange.to);
  }

  @computed
  get mapEnabled(): Boolean {
    return !!this.booking && this.booking.seeMeApiHash != null && isToday(this.bookingTimeRange.from);
  }

  @action
  setBookingTimeRange(timeRange: TimeRange): void {
    this.bookingTimeRange = timeRange;
    this.allowChangeTime = isBefore(endOfToday(), timeRange.from);
  }

  @computed
  get availableDates(): Array<Required<IDateRange>> | null {
    if (!this.timeRanges.length) {
      return null;
    }

    const startDays: string[] = [];
    const dates: Array<Required<IDateRange>> = this.timeRanges
      .map((timeRange) => ({
        from: startOfDay(timeRange.from),
        to: toDate(timeRange.to!),
      }))
      .filter(({ from }) => {
        const startDay = from.toISOString();
        const isDuplicate = startDays.includes(startDay);
        startDays.push(startDay);
        return !isDuplicate;
      });

    if (dates.length === 1) {
      dates[0].to.setDate(dates[0].to.getDate() + 1);
    }

    return dates;
  }

  @action
  handleDateSelect = (input: DatepickerValue, showAll?: Boolean): void => {
    this.moreTimesAvailable = false;
    this.selectedTime = input as any;
    this.selectableTimeRanges = observable.array<TimeRange>();
    const priorityTimeRanges: TimeRange[] = [];
    if (this.selectedTime) {
      this.timeRanges.forEach((range) => {
        if (isSameDay(range.from, this.selectedTime)) {
          this.selectableTimeRanges.push(range);
          if (range.priority) {
            priorityTimeRanges.push(range);
          }
        }
      });
      if (!showAll && priorityTimeRanges.length && this.selectableTimeRanges.length > priorityTimeRanges.length) {
        this.moreTimesAvailable = true;
        this.selectableTimeRanges = observable(priorityTimeRanges);
      }
    }
  };

  handleShowMoreTimes(): void {
    this.handleDateSelect(this.selectedTime, true);
  }

  @action
  handleBookingUpdated = (selectedTime: TimeRange, newBooking: TechnicianBookingHash): void => {
    this.selectedTimeRange = selectedTime;
    this.updatedBooking = newBooking;
    this.isCardLoading = false;
  };

  @action
  handleBookingUpdatedError = (selectedTime: TimeRange): void => {
    this.addNotice('myTechnician.booking.not_available', 'error');
    this.selectedTimeRange = null;
    this.updatedBooking = null;
    const availableTimeRanges = this.selectableTimeRanges.filter((time) => time.to !== selectedTime.to && time.from !== selectedTime.from);
    this.selectableTimeRanges = observable(availableTimeRanges);
    this.isCardLoading = false;
  };

  handleTimeSelect = async (input: TimeRange) => {
    const { localeStore } = this.context;

    this.isCardLoading = true;
    const language: string = localeStore.currentLocale;
    const scheduleItem: ScheduleItem = convertTimeRangeToScheduleItem(input);

    if (!this.hash) return;

    await WorkforceApi.updateBookingByHash(this.hash, scheduleItem, language)
      .then((response) => this.handleBookingUpdated(input, response))
      .catch(() => this.handleBookingUpdatedError(input));
  };

  @computed
  get hash() {
    const { navigationStore } = this.context;

    const queryString = parse(navigationStore.location.search, { ignoreQueryPrefix: true });

    return queryString['hash']?.toString();
  }

  @computed
  get redirectUrl() {
    const { navigationStore } = this.context;
    const queryString = parse(navigationStore.location.search, { ignoreQueryPrefix: true });
    return queryString['redirect_url'];
  }

  @action
  setBooking = (technicianBooking: TechnicianBooking): void => {
    this.booking = TechnicianBooking.fromJson(technicianBooking);
    this.setBookingTimeRange({
      from: toDate(this.booking.startTime),
      to: toDate(this.booking.endTime),
    });
  };

  @action
  handleGetBookingByHashError = (): void => {
    this.booking = null;
    this.addNotice('myTechnician.not_found', 'error');
    this.isCardLoading = false;
  };

  private fetchTechnicianBooking = async (hash: string) => {
    this.isCardLoading = true;

    try {
      const technicianBooking = await WorkforceApi.getBookingByHash(hash);
      this.setBooking(technicianBooking);
      await this.fetchEta(hash);
    } catch {
      this.handleGetBookingByHashError();
    }

    this.isCardLoading = false;
  };

  @action
  handleFetchSchedules = (schedules: Schedules): void => {
    this.timeRanges.replace(schedules.schedules.filter((item) => !!item).map(convertScheduleItemToTimeRange));
    if (this.timeRanges.length > 0) {
      this.handleDateSelect(this.timeRanges[0].from);
    }
    this.showChangeTime = true;
    this.isCardLoading = false;
  };

  private fetchSchedules = async () => {
    const { localeStore } = this.context;

    this.isCardLoading = true;
    const language: string = localeStore.currentLocale;

    if (!this.hash) return;

    const schedules = await WorkforceApi.getSchedulesByHash(this.hash, language);
    this.handleFetchSchedules(schedules);
  };

  @action
  setTechnicianLocation = (location: VehicleLocation): void => {
    if (!this.technicianLocation || this.technicianLocation.timestamp !== location.timestamp) {
      this.technicianLocation = location;
    }
  };

  @action
  setClientPosition = (location: google.maps.LatLngLiteral): void => {
    this.clientPosition = location;
  };

  @computed
  get technicianPosition(): google.maps.LatLngLiteral | undefined {
    if (this.technicianLocation) {
      return { lat: Number(this.technicianLocation.latitude), lng: Number(this.technicianLocation.longitude) };
    }
    return undefined;
  }

  private fetchTechnicianPosition = (): void => {
    SeeMeApi.getLastData(this.booking!.seeMeApiHash).then((location) => this.setTechnicianLocation(location));
  };

  @action
  handleTechnician = (technician: Technician) => {
    this.technician = technician;
  };

  private fetchEta = async (hash: string) => {
    const booking = await WorkforceApi.getEta(hash);
    this.handleFetchEta(booking.eta);
  };

  @action
  handleFetchEta = (eta: number | null) => {
    if (this.booking) {
      this.booking.eta = eta;
    }
  };

  @action
  handleFetchEtaError = () => {
    if (this.booking) {
      this.booking.eta = null;
    }
  };

  @computed
  get etaTranslationKey(): string {
    if (!this.booking || this.booking.eta === null) {
      return '';
    }
    if (this.booking.eta === 0) {
      return 'mytechnician.visit_info.eta0';
    } else if (this.booking.eta === 1) {
      return 'mytechnician.visit_info.eta1';
    } else {
      return 'mytechnician.visit_info.eta2';
    }
  }

  @action
  private addNotice(key: string, type?: 'error' | 'success' | 'employee'): void {
    this.notice = new Message({ key, options: { type } });
    doScrolling(0, 500);
  }

  @action
  private clearNotice(): void {
    this.notice = null;
  }

  private clearLocationInterval(): void {
    if (this.locationIntervalId) {
      clearInterval(this.locationIntervalId);
    }
  }

  handleNoticeClose(): void {
    this.clearNotice();
  }

  handleOpenCalendar(): void {
    const { translateStore } = this.context;

    const currentUrl = document.URL;
    const title = translateStore.translate('myTechnician.calendar.title');
    const description = translateStore.translate('myTechnician.calendar.description', {
      bookingDate: format(this.bookingTimeRange.from, 'dd.MM.Y'),
      bookingStartTime: format(this.bookingTimeRange.from, 'HH:mm'),
      bookingEndTime: format(this.bookingTimeRange.to!, 'HH:mm'),
      technicianName: this.booking!.employeeFirstName,
      url: currentUrl,
    });
    const icsData = [
      'BEGIN:VCALENDAR',
      'METHOD:PUBLISH',
      'PRODID:-//Telia//MinuTehnik',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'UID:' + this.hash + '@telia.ee',
      'ORGANIZER;CN=Minu Tehnik:mailto:minutehnik@telia.ee',
      'SUMMARY:' + title,
      'DESCRIPTION:' + description,
      'DTSTAMP:' + formatICSTimestamp(new Date(Date.now())),
      'DTSTART:' + formatICSTimestamp(toUtcTimeZone(this.bookingTimeRange.from)),
      'DTEND:' + formatICSTimestamp(toUtcTimeZone(this.bookingTimeRange.to!)),
      'LOCATION:' + this.formatLocation(this.booking!.location),
      'STATUS:CONFIRMED',
      'BEGIN:VALARM',
      'ACTION:DISPLAY',
      'TRIGGER:-PT30M',
      'END:VALARM',
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\r\n');

    window.location.assign('data:text/calendar,' + encodeURIComponent(icsData));
  }

  openSsoModalManuallyAndNavigateToSettings(): void {
    const { ssoStore, navigationStore } = this.context;

    if (ssoStore.isLoggedInWithHardAuth && ssoStore.isPrivateCustomer) {
      navigationStore.navigateTo(NavigationStore.SETTINGS_CONTACTS_LR);
      return;
    }

    this.disposeSsoReactions();
    this.ssoReactions = [
      reaction(
        () => ssoStore.customer,
        () => {
          this.disposeSsoReactions();
          this.redirectToSettings();
        },
        {
          name: 'user logged in -> navigate to settings',
        }
      ),
      reaction(
        () => ssoStore.isSignInInvoked,
        (isSignInRequired) => {
          /* istanbul ignore else */
          if (!isSignInRequired) {
            this.disposeSsoReactions();
          }
        },
        {
          name: 'user closed sso modal -> dispose reactions',
        }
      ),
    ];
    ssoStore.invokeSignIn();
  }

  cancelBooking(): void {
    if (typeof this.hash !== 'string') {
      throw new Error('Hash is not a string: ' + this.hash);
    }
    const scheduleItem: ScheduleItem = convertTimeRangeToScheduleItem(this.bookingTimeRange);
    WorkforceApi.cancelBookingByHash(this.hash, scheduleItem, this.booking!.location, this.cancellationReason)
      .then(() => this.handleBookingCancelled())
      .catch(() => this.handleBookingCancelledError());
  }

  @action
  handleBookingCancelled = (): void => {
    this.isCancelled = true;
    this.showCancelledModal = true;
    this.toggleCancellationModal();
    this.redirect();
  };

  @action
  handleBookingCancelledError = (): void => {
    this.toggleCancellationModal();
    this.addNotice('myTechnician.cancel.error', 'error');
  };

  @action
  toggleCancellationModal(): void {
    this.showCancellationModal = !this.showCancellationModal;
    this.checkIfUserIsEmployee();
  }

  @action
  updateCancellationReason = (value: string) => {
    this.cancellationReason = value;
  };

  @action
  toggleCancelledModal(): void {
    this.showCancelledModal = !this.showCancelledModal;
    this.booking = null;
  }

  private checkIfUserIsEmployee(): void {
    const { ssoStore } = this.context;
    if (ssoStore.dealer) {
      this.isEmployee = true;
    }
  }

  private redirect(): void {
    const { navigationStore } = this.context;

    const url = this.redirectUrl;
    if (!url || typeof url !== 'string') {
      return;
    }
    window.setTimeout(() => navigationStore.navigateTo(url), REDIRECT_TIME_MS);
  }

  private disposeSsoReactions(): void {
    this.ssoReactions.forEach((dispose) => dispose());
    this.ssoReactions = [];
  }

  private redirectToSettings(): void {
    const { navigationStore, ssoStore } = this.context;

    if (ssoStore.isPrivateCustomer) {
      navigationStore.navigateTo(NavigationStore.SETTINGS_CONTACTS_LR);
    }
  }

  private formatLocation(location: Location): string {
    return location.streetBgName + (location.bgNum ? ' ' + location.bgNum : '') + '\\, ' + location.cityCounty;
  }
}
