import React from 'react';
import { observer } from 'mobx-react';
import { Button, Grid, GridColumn, Text } from 'telia-front-react';
import IProps from './IProps';
import TimeRange from '../../../../../common/components/time/range/TimeRange';
import Cancellation from './components/modal/cancellation';
import Cancelled from './components/modal/cancelled';

const Active = observer((props: IProps) => {
  const {
    translate,
    translateHtml,
    bookingTimeRange,
    isCancelled,
    booking,
    showMap,
    etaTranslationKey,
    showCancellationModal,
    isEmployee,
    cancellationReason,
    showCancelledModal,
    redirectUrl,
    handleOpenChangeTime,
    handleOpenCalendar,
    handleToggleCancellationModal,
    handleCancellationReasonChange,
    handleCancellation,
    handleToggleCancelledModal,
    handleToggleShowMap,
  } = props;

  return (
    <React.Fragment>
      <Grid align={['middle-sm']}>
        <GridColumn width={['md-3']}>
          <div>
            <TimeRange translate={translate} timeRange={bookingTimeRange} />
          </div>
          <div>
            <>
              <Button
                type="withdrawal"
                text={translate('myTechnician.cancel.cancel')}
                link
                iconRight="#arrow-right"
                className={booking && booking.bookingType === 'INCIDENT' ? '' : 'hidden'}
                onClick={handleToggleCancellationModal}
              />
              {!isCancelled ? (
                <Cancellation
                  isOpen={showCancellationModal}
                  onClose={handleToggleCancellationModal}
                  isEmployee={isEmployee}
                  cancellationReason={cancellationReason}
                  handleCancellationReasonChange={handleCancellationReasonChange}
                  handleCancellation={handleCancellation}
                  translate={translate}
                />
              ) : (
                <Cancelled
                  isOpen={showCancelledModal}
                  onClose={handleToggleCancelledModal}
                  redirectUrl={redirectUrl}
                  translate={translate}
                />
              )}
            </>
          </div>
        </GridColumn>
        <GridColumn width={['md-4']}>
          <Button
            id="myTechnician-button-open-change-time"
            className="myTechnician__btn-margin"
            text={translate('mytechnician.change_time')}
            iconRight="#arrow-right"
            onClick={handleOpenChangeTime}
          />
          <Button
            id="myTechnician-button-open-calendar"
            className="myTechnician__btn-margin"
            text={translate('myTechnician.calendar.save')}
            variant="secondary"
            iconRight="#arrow-right"
            onClick={handleOpenCalendar}
          />
        </GridColumn>
        <GridColumn width={['md-5']}>
          <Grid align={['end-sm']}>
            <GridColumn width={['xs-12', 'md-9']}>
              <Text>{translate('mytechnician.technician.name')}</Text>
              <Text className="text-bold">{booking && booking.employeeFirstName}</Text>
              <Button
                id="myTechnician-button-show-map"
                text={translate('mytechnician.technician.location')}
                link
                iconRight={showMap ? '#arrow-up' : '#arrow-down'}
                onClick={handleToggleShowMap}
              />
              {booking && booking.eta && (
                <div id="myTechnician-show-eta">
                  <span
                    dangerouslySetInnerHTML={translateHtml(etaTranslationKey, {
                      visitCount: booking.eta,
                    })}
                  />
                </div>
              )}
            </GridColumn>
          </Grid>
        </GridColumn>
      </Grid>
    </React.Fragment>
  );
});

export default Active;
