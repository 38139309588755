import ProductUserCompact, { ProductUserCompactResource } from '../../types/ProductUserCompact';
import { ApiResponse, makeRequest } from '@teliaee/sf.core';
import { URI } from 'uri-template-lite';
import { ApiUrl } from '../../../common/constants/api';

export const readProductUsers = () =>
  makeRequest<ProductUserCompact>({
    url: URI.expand(ApiUrl.PRODUCT_USERS, {}),
    transformResponse: ({ ...value }: ApiResponse<ProductUserCompactResource>) => ({
      data: Object.values(value),
      errors: value.errors,
    }),
  });
