import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, CardContent, Container, Grid, GridColumn, Notice, Overlay, Separator, Text } from 'telia-front-react';
import RootStoreContext from '../../common/root';
import { MyTechnicianStore } from './store';
import Attendance from './components/attendance';
import Active from './components/booking/active';
import Past from './components/booking/past';
import Map from './components/map';
import Change from './components/booking/change';
import { PlaceholderMapping } from '@teliaee/sf.core';

const MyTechnician = observer(() => {
  const context: any = React.useContext(RootStoreContext);
  const [store] = useState(() => new MyTechnicianStore(context));
  const { translateStore } = context;

  useEffect(() => {
    store.initialize();
  }, [store]);

  return (
    <div className="myTechnician__background">
      <Container>
        <Grid align={['center-sm']} className="myTechnician__grid">
          <GridColumn width={['xs-12', 'xl-10', 'xxl-9']}>
            <div className="myTechnician__title-margin">
              <Separator spacing="medium" color="invisible" />

              <h1>{translateStore.translate('mytechnician.banner')}</h1>

              <Separator spacing="small" color="invisible" />

              {store.booking ? (
                <>
                  <Text className="h5">{translateStore.translate('mytechnician.greeting', { clientName: store.booking.clientName })}</Text>
                  <p
                    className="h5"
                    dangerouslySetInnerHTML={translateStore.translateHtml(
                      store.bookingActive ? 'mytechnician.greeting2' : 'myTechnician.feedback.info'
                    )}
                  />
                </>
              ) : (
                <Separator spacing="medium" color="invisible" />
              )}
            </div>

            {store.notice ? (
              <React.Fragment>
                <Separator spacing="xxsmall" color="invisible" />
                <Notice
                  key={store.notice.key}
                  type={store.notice.type}
                  className="myTechnician__notice"
                  onCloseClick={() => store.handleNoticeClose()}
                >
                  <Text>{store.notice.key && translateStore.translate(store.notice.key)}</Text>
                </Notice>
              </React.Fragment>
            ) : (
              ''
            )}

            <Separator spacing="small" color="invisible" />

            {store.booking ? (
              <Card padding="medium">
                <CardContent>
                  {store.isCardLoading ? <Overlay spinner /> : ''}
                  {store.bookingActive ? (
                    store.showChangeTime ? (
                      <Change
                        translate={(key: string) => translateStore.translate(key)}
                        availableDates={store.availableDates}
                        selectedTime={store.selectedTime}
                        moreTimesAvailable={store.moreTimesAvailable}
                        selectableTimeRanges={store.selectableTimeRanges}
                        selectedTimeRange={store.selectedTimeRange}
                        handleCloseChangeTime={() => store.closeChangeTime()}
                        handleSaveBookingTime={() => store.saveBookingTime()}
                        handleShowMoreTimes={() => store.handleShowMoreTimes()}
                        handleTimeSelect={(input) => store.handleTimeSelect(input)}
                        handleDateSelect={(input, showAll) => store.handleDateSelect(input, showAll)}
                      />
                    ) : (
                      <Active
                        translate={(key: string) => translateStore.translate(key)}
                        translateHtml={(key: string, params?: PlaceholderMapping) => translateStore.translateHtml(key, params)}
                        bookingTimeRange={store.bookingTimeRange}
                        isCancelled={store.isCancelled}
                        booking={store.booking}
                        showMap={store.showMap}
                        etaTranslationKey={store.etaTranslationKey}
                        showCancellationModal={store.showCancellationModal}
                        isEmployee={store.isEmployee}
                        cancellationReason={store.cancellationReason}
                        showCancelledModal={store.showCancelledModal}
                        redirectUrl={store.redirectUrl}
                        handleOpenChangeTime={() => store.openChangeTime()}
                        handleOpenCalendar={() => store.handleOpenCalendar()}
                        handleToggleCancellationModal={() => store.toggleCancellationModal()}
                        handleCancellationReasonChange={(inputValue: string) => store.updateCancellationReason(inputValue)}
                        handleCancellation={() => store.cancelBooking()}
                        handleToggleCancelledModal={() => store.toggleCancelledModal()}
                        handleToggleShowMap={() => store.toggleShowMap()}
                      />
                    )
                  ) : (
                    <Past
                      translate={(key: string) => translateStore.translate(key)}
                      booking={store.booking}
                      bookingTimeRange={store.bookingTimeRange}
                    />
                  )}
                </CardContent>
              </Card>
            ) : (
              ''
            )}

            {store.showMap ? (
              <Map
                mapEnabled={store.mapEnabled}
                technicianPosition={store.technicianPosition}
                booking={store.booking}
                etaTranslationKey={store.etaTranslationKey}
                clientPosition={store.clientPosition}
                setClientPosition={store.setClientPosition}
                translate={translateStore.translate}
                translateHtml={translateStore.translateHtml}
              />
            ) : (
              ''
            )}

            {store.bookingActive ? (
              <React.Fragment>
                <Separator spacing="medium" color="invisible" />

                <Attendance
                  title={translateStore.translate('mytechnician.visit_info.header')}
                  bookingType={store.booking!.bookingType}
                  openSsoLogin={() => store.openSsoModalManuallyAndNavigateToSettings()}
                  translate={(key: string) => translateStore.translate(key)}
                />
              </React.Fragment>
            ) : (
              ''
            )}
          </GridColumn>
        </Grid>
      </Container>
    </div>
  );
});

export default MyTechnician;
