import { observer } from 'mobx-react';
import React from 'react';
import { Modal, Separator, Icon, Spinner } from 'telia-front-react';
import { IProps } from './IProps';

const Cancelled = observer((props: IProps) => {
  const { isOpen, onClose, translate, redirectUrl } = props!;

  return (
    <Modal isOpen={isOpen} size="6-col" onClose={onClose}>
      <div className="text-center" id="myTechnician-cancelled">
        <Icon modifiers={'#check-round'} size="xl" className="text-success" />
        <Separator color="invisible" spacing="xxsmall" />
        <h4>{translate('myTechnician.cancel.success')}</h4>
        {redirectUrl ? (
          <>
            <Separator color="invisible" spacing="xxsmall" />
            <Spinner text={translate('myTechnician.cancel.redirect')} />
          </>
        ) : null}
      </div>
    </Modal>
  );
});

export default Cancelled;
