import React, { useContext, useEffect, useState } from 'react';
import RootStoreContext from '../../root';
import { Store } from './store';
import IProps from './IProps';
import IContext from './IContext';
import { observer } from 'mobx-react';

/**
 * Detects `auth-goto` query parameter in location URL and invokes SSO modal for user sign-in and redirection.
 */
const AuthRedirect: React.FC<IProps> = observer((props) => {
  const context: IContext = useContext(RootStoreContext);
  const [store] = useState(() => new Store(context));

  useEffect(() => {
    store.init();
    return () => store.terminate();
  }, []);

  return store.isAuthRedirectInProgress ? null : <>{props.children}</>;
});

export default AuthRedirect;
