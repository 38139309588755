import { action, computed, observable, makeObservable } from 'mobx';
import { ErrorUtil } from '@teliaee/sf.core';

export enum State {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  FAILED = 'FAILED',
  LOADED = 'LOADED',
}

type AddOrRemoveEventListener = Pick<EventTarget, 'addEventListener' | 'removeEventListener'>;
type GetPromise<Data> = () => Promise<Data>;
type GetPromises<P1 = any, P2 = any, P3 = any> =
  | [GetPromise<P1>]
  | [GetPromise<P1>, GetPromise<P2>]
  | [GetPromise<P1>, GetPromise<P2>, GetPromise<P3>];
type GetPromisesDataType<T> = T extends GetPromises<infer P1, infer P2, infer P3> ? [P1, P2, P3] : any;

interface LoadableOptions<P, Data> {
  mapToData?: (data: GetPromisesDataType<P>) => Data;
  prevent?: AddOrRemoveEventListener;
}

/**
 * Returns Loadable that only has getter methods. Useful for encapsulating special load logic.
 */
export type ReadonlyLoadable<Data, E = Error> = Without<Loadable<Data, E>, 'load' | 'loadAll' | 'reset'>;

/**
 * Data - Promise return type. Loaded data type.
 */
export class Loadable<Data, E = Error> {
  /**
   * _isLoading is non-observable value for loadInternal function.
   * Avoids load method triggering mobx autorun.
   */
  private _isLoading = false;
  @observable private _state: State = State.INITIAL;
  @observable private _currentData: Data | undefined;
  @observable private _currentError: E | undefined;

  static fromLoad<Data, E = Error>(promise: GetPromise<Data>, prevent?: AddOrRemoveEventListener) {
    const loadable = new Loadable<Data, E>();
    loadable.load(promise, prevent);
    return loadable;
  }

  constructor() {
    makeObservable(this);
  }

  reset() {
    this.setLoadable({
      currentData: undefined,
      currentError: undefined,
      state: State.INITIAL,
    });
  }

  /**
   * Preventing user events like keydown, keyup, mouseClick.
   */
  preventDefault(event: Pick<Event, 'preventDefault'>) {
    event.preventDefault();
  }

  prevent(current: AddOrRemoveEventListener | undefined, apply: keyof AddOrRemoveEventListener) {
    if (current) {
      const addOrRemove = current[apply].bind(current);
      addOrRemove('keydown', this.preventDefault);
      addOrRemove('keyup', this.preventDefault);
    }
  }

  /**
   * Should not happen. Helps to catch bugs and race conditions gracefully.
   * Gives previous promise 10 seconds of grace.
   *
   * @throws If previous promise takes more then 10 seconds.
   */
  async waitIfLoading<S, T>(startTime = Date.now()): Promise<void> {
    ErrorUtil.pushError(new Error('Waiting for promise.'));
    const tenSeconds = 10000;
    const giveUpTime = startTime + tenSeconds;
    if (Date.now() > giveUpTime) {
      const error = new Error('Invalid state. Could not load because previous load did not finish in time.');
      ErrorUtil.pushError(error);
      throw error;
    }
    await sleep(200);
    if (this._isLoading) {
      await this.waitIfLoading(startTime);
    }

    async function sleep(ms: number) {
      return new Promise((res: any) => setTimeout(res, ms));
    }
  }

  /**
   * See #loadInternal
   */
  async loadAll<P extends GetPromises>({ mapToData, prevent }: LoadableOptions<P, Data>, ...promises: P): Promise<void> {
    return this.loadInternal(promises, { mapToData, prevent });
  }

  /**
   * See #loadInternal
   */
  async load(promise: GetPromise<Data>, prevent?: AddOrRemoveEventListener): Promise<void> {
    return this.loadInternal(promise, {
      prevent,
    });
  }

  /**
   * Function to ease saving async function state. Loading, loaded, error to observable.
   *
   * Calls @action twice, when loading and when data or error returned.
   *
   * @param promises - Executed after setting loading state. When finished then setting error or data state.
   * @param mapData - When using Promise.all result [] must be mapped into Data type.
   * @param prevent - Prevents keydown and keyup and returns this while loading.
   *
   * @return - Properties are observed. Useful for doing or showing something when loading or error or data.
   */
  private async loadInternal<P extends GetPromises>(
    promises: GetPromise<Data> | P,
    { mapToData, prevent }: LoadableOptions<P, Data>
  ): Promise<void> {
    if (Array.isArray(promises) && !mapToData) {
      throw new Error('Illegal state. Should pass mapToData when loadable loadAll.');
    }
    if (prevent && this._isLoading) {
      ErrorUtil.pushError(new Error('Invalid state. Did not prevent event with spinner or preventDefault.'));
      return;
    }
    if (this._isLoading) {
      await this.waitIfLoading();
    }
    this.prevent(prevent, 'addEventListener');
    this.setLoading();
    try {
      if (Array.isArray(promises)) {
        const promiseAll = Promise.all(promises.map((promise) => promise())) as Promise<GetPromisesDataType<P>>;
        this.setLoaded(await promiseAll.then(mapToData));
      } else {
        this.setLoaded(await promises());
      }
    } catch (e) {
      this.setError(e);
    }
    this.prevent(prevent, 'removeEventListener');
  }

  @action
  private setLoading() {
    this._state = State.LOADING;
    this._isLoading = true;
  }

  @action
  private setLoaded(data: Data) {
    this._currentData = data;
    this._state = State.LOADED;
    this._isLoading = false;
  }

  @action
  private setError(error: E) {
    this._currentError = error;
    this._state = State.FAILED;
    this._isLoading = false;
  }

  @computed
  get state() {
    return this._state;
  }

  @computed
  get isInitial() {
    return this._state === State.INITIAL;
  }

  @computed
  get isLoading() {
    return this._state === State.LOADING;
  }

  @computed
  get isLoaded() {
    return this._state === State.LOADED;
  }

  @computed
  get isFailed() {
    return this._state === State.FAILED;
  }

  @computed
  get isFinished() {
    return this.isLoaded || this.isFailed;
  }

  @computed
  get data() {
    return this._currentData;
  }

  @computed
  get error() {
    return this._currentError;
  }

  @computed
  get currentData() {
    return this.isLoading ? undefined : this._currentData;
  }

  @computed
  get currentError() {
    return this.isLoading ? undefined : this._currentError;
  }

  @computed
  get previousData() {
    return this.isLoading ? this._currentData : undefined;
  }

  @computed
  get previousError() {
    return this.isLoading ? this._currentError : undefined;
  }

  @action
  private setLoadable(req: Pick<Loadable<Data, E>, 'currentData' | 'currentError' | 'state'>) {
    this._currentData = req.currentData;
    this._currentError = req.currentError;
    this._state = req.state;
    this._isLoading = req.state === State.LOADING;
  }
}
