import React, { useContext } from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { observer } from 'mobx-react';
import IContext from './IContext';
import RootStoreContext from '../../root';
import IProps from './IProps';


const Head: React.FC<IProps> = observer((props) => {
  const { localeStore, translateStore }: IContext = useContext(RootStoreContext);

  const helmetProps: HelmetProps = {
    ...props,
    base: { href: '/' },
    htmlAttributes: {
      ...props.htmlAttributes,
      lang: localeStore.currentLocale,
    } as HelmetProps['htmlAttributes'],
    title: props.title && translateStore.translate(props.title),
    meta: props.meta || [],
  };

  return (
    <Helmet
      titleTemplate={translateStore.translate('head.title.template')}
      defaultTitle={translateStore.translate('head.title.default')}
      {...helmetProps}
    />
  );
});

export default Head;
