import { action, computed, makeObservable, observable } from 'mobx';
import { matchPath } from 'react-router-dom';
import { RouterStore, syncHistoryWithStore, SynchronizedHistory } from '@superwf/mobx-react-router';
import { createBrowserHistory } from 'history';
import { pathToRegexp } from 'path-to-regexp';
import { parse, stringify } from 'qs';
import SsoStore from '../sso/store';
import { NavigationEvent } from 'telia-megamenu';
import RouteKey from '../constants/keys';
import LocaleStore from '../locale/store';
import RouteProps, { PathType } from '../types/RouteProps';
import { getExternalLinks } from '../config/api';
import { ExternalLink, ExternalLinks } from '../types/ExternalLink';

export interface INavigationStoreContext {
  localeStore: LocaleStore;
  ssoStore: SsoStore;
}

class NavigationStore {
  static PARAM_NAME_PRODUCT_AND_ORIGIN = 'productIdAndOrigin';

  static SETTINGS_LR = '/era/seaded';
  static SETTINGS_CONTACTS_LR = `${NavigationStore.SETTINGS_LR}/kontaktisikud`;
  static REGISTER = '/konto/registreerimine';
  static CUSTOMER_SEARCH = '/teenindus/otsing';
  static TECHNICIAN = '/minutehnik';

  private router: RouterStore;

  @observable routeKey: RouteKey;
  @observable externalLinks: Map<ExternalLink, string> = new Map();

  private static setInitialHistoryState(): void {
    const state = { spaView: true };
    window.history.replaceState({ state }, '');
  }

  constructor(private routes: RouteProps[]) {
    makeObservable(this);
    this.router = new RouterStore();
    syncHistoryWithStore(createBrowserHistory(), this.router);
    this.history.subscribe(this.handleRouteChange);
  }

  init(): void {
    NavigationStore.setInitialHistoryState();
    this.fetchExternalLinks();
  }

  getMatchingRouteProps(pathname: string): RouteProps | undefined {
    return this.routes.find((routeProps) => pathToRegexp(routeProps.path).test(pathname));
  }

  shouldUseFullPageReload(pathname?: string): boolean {
    if (pathname === undefined) return false;
    return false;
  }

  onPathChange = (path: NavigationEvent): void => {
    path.preventDefault();
    this.navigateTo(path.currentTarget);
  };

  isExternalPath(url: string | URL): boolean {
    if (typeof url === 'string') {
      url = new URL(url, window.location.origin);
    }

    return url.origin !== window.location.origin || !this.getMatchingRouteProps(url.pathname);
  }

  navigateTo = (url: string | URL, target?: string, replace = false, spaView = true): void => {
    if (target) {
      window.open(url.toString(), target);
      return;
    }

    if (typeof url === 'string') {
      url = new URL(url, window.location.origin);
    }

    if (this.isExternalPath(url) || this.shouldUseFullPageReload(url.pathname)) {
      window.location.href = url.toString();
      return;
    }

    url = url.toString().replace(url.origin, '');

    if (url !== this.currentUrl) {
      this.router.history[replace ? 'replace' : 'push'](url, { spaView });
    }
  };

  navigateBack = (): void => this.router.history.goBack();

  navigateBackWithReload = (): void => {
    this.navigateBack();
    window.location.reload();
  };

  redirectToPublicFrontPage = (): void => {
    this.navigateTo(this.getExternalLink(ExternalLink.TELIAWEB));
  };

  matchesCurrentPaths(routePath: PathType): boolean {
    if (Array.isArray(routePath)) {
      return routePath.reduce((prevMatch: boolean, path: string) => {
        const nextMatch = this.matchesCurrentPath(path);
        return prevMatch || nextMatch;
      }, false);
    }
    return this.matchesCurrentPath(routePath);
  }

  matchesCurrentPath(routePath: string): boolean {
    if (!routePath.startsWith(LocaleStore.pathLocalePrefixPattern)) {
      routePath = LocaleStore.pathLocalePrefixPattern + routePath;
    }

    return pathToRegexp(routePath).test(this.router.location.pathname);
  }

  getExternalLink(key: ExternalLink, path?: string): string {
    return (this.externalLinks.get(key) || '') + (path || '');
  }

  findUrlParamValue(param: string): string | undefined {
    const queryString: { [key: string]: any | undefined } = parse(this.router.location.search, { ignoreQueryPrefix: true });
    return queryString[param];
  }

  findUrlParamValueAsArray(param: string): string[] {
    let paramValueArr: string[] = [];
    const paramValue = this.findUrlParamValue(param);
    if (!!paramValue) {
      paramValueArr = paramValue.split(',');
    }
    return paramValueArr;
  }

  constructUrl(origin: string, params?: {}): string {
    origin = origin.replace(/\/$/, '');
    if (!!params) {
      return origin + stringify(params, { addQueryPrefix: true });
    }
    return origin;
  }

  getInfoWebLink = (path: string) => this.getExternalLink(ExternalLink.TELIAWEB, path);

  getAntoLink = (path: string) => this.getExternalLink(ExternalLink.ANTO, path);

  @computed
  get location() {
    return this.router.location;
  }

  @computed
  get history() {
    return this.router.history as SynchronizedHistory;
  }

  @computed
  get isProductManageFlow(): boolean {
    const queryString = parse(this.router.location.search, { ignoreQueryPrefix: true });
    return !!queryString['product'];
  }

  @computed
  get isBusinessContext(): boolean {
    const queryString = parse(this.router.location.search, { ignoreQueryPrefix: true });
    return queryString['context'] === 'business';
  }

  @computed
  get isInternetRedirectFlow(): boolean {
    const queryString = parse(this.router.location.search, { ignoreQueryPrefix: true });
    return queryString.hasOwnProperty('productOfferingCode') && !!queryString['addressData'];
  }

  @computed
  get routeParams(): { [key: string]: any } | undefined {
    if (!this.currentRouteProps) {
      return undefined;
    }

    const { pathname } = this.router.location;
    const { path } = this.currentRouteProps;
    const match = matchPath(pathname, { path });

    return match?.params;
  }

  @computed
  private get currentRouteProps(): RouteProps | undefined {
    const { pathname } = this.router.location;
    return this.getMatchingRouteProps(pathname);
  }

  getRouteParam(paramName: string): string | undefined {
    const params = this.routeParams;
    if (!params) {
      return undefined;
    }

    const param = params[paramName];
    if (!param) {
      return undefined;
    }
    return param;
  }

  @computed
  private get currentUrl(): string {
    const { pathname, search = '', hash = '' } = this.router.location;
    return pathname + search + hash;
  }

  @action
  private setExternalLinks(response: ExternalLinks): void {
    this.externalLinks = new Map(Object.entries(response) as Array<[ExternalLink, string]>);
  }

  @action
  private handleRouteChange = (): void => {
    this.routeKey = this.currentRouteProps ? this.currentRouteProps.key : RouteKey.NOT_FOUND;
  };

  private async fetchExternalLinks(): Promise<void> {
    return getExternalLinks()
      .then((response) => this.setExternalLinks(response))
      .catch(() => console.warn('Failed to fetch environment based links '));
  }
}

export default NavigationStore;
