import { TimeRange } from '../../common/types/booking/TimeRange';
import ScheduleItem from '../../common/types/booking/ScheduleItem';

export const convertScheduleItemToTimeRange = (scheduleItem: ScheduleItem): TimeRange =>
  Object.assign(Object.create({}), {
    ...scheduleItem,
    from: new Date(scheduleItem.from),
    to: scheduleItem.to ? new Date(scheduleItem.to) : undefined,
  });

export const convertTimeRangeToScheduleItem = (timeRange: TimeRange): ScheduleItem => ({
  ...timeRange,
  from: timeRange.from.toISOString(),
  to: timeRange.to && timeRange.to.toISOString(),
});
