import React from 'react';
import IProps from './IProps';
import { Icon, Text } from 'telia-front-react';
import { format } from '../../../../common/utils/date';

const TimeRange = (props: IProps) => {
  const { translate, timeRange } = props;

  return (
    <React.Fragment>
      <Text className="h3">{format(timeRange.from, 'DD.MM.Y')}</Text>
      <Text className="text">{translate('mytechnician.timespan')}</Text>
      <span className="h4">
        <Icon modifiers="#time-round" size="xs" /> {format(timeRange.from, 'HH:mm')}
        {' - '}
        {format(timeRange.to, 'HH:mm')}
      </span>
    </React.Fragment>
  );
};

export default TimeRange;
