import SsoStore from '../sso/store';
import LocaleStore from '../locale/store';
import TranslateStore from '../translations/store';
import NavigationStore from '../navigation/store';
import RouteProps from '../types/RouteProps';
import OnlineHelpStore from '../online/store';
import { CookieConsentStore } from '../cookies/store';
import IRootStore from './IStore';

import { FeatureStore } from '../features';
import MandateCheckerStore from '../mandate/store';
import ProductUserStore from '../product/user/store';
import MegamenuStore from '../megamenu';
import CrmStore from '../crm/store';
import { MessageStore } from '../message';
import { ViewPortStore } from '../viewPort';

export interface RootStoreInitData {
  routes: RouteProps[];
}

export class RootStore implements IRootStore {
  static async createRootStore(initData: RootStoreInitData) {
    const stores: IRootStore = {} as IRootStore;

    stores.featureStore = new FeatureStore();
    await stores.featureStore.init();

    stores.navigationStore = new NavigationStore(initData.routes);
    await stores.navigationStore.init();

    stores.localeStore = new LocaleStore(stores);
    await stores.localeStore.init();

    stores.translateStore = new TranslateStore(stores);
    await stores.translateStore.init();

    stores.cookieConsentStore = new CookieConsentStore();

    stores.ssoStore = new SsoStore(stores);
    await stores.ssoStore.init();

    stores.productUserStore = new ProductUserStore(stores as RootStore);
    await stores.productUserStore.init();

    stores.crmStore = new CrmStore(stores);
    stores.mandateCheckerStore = new MandateCheckerStore(stores);
    stores.messageStore = new MessageStore();
    stores.onlineHelpStore = new OnlineHelpStore(stores);
    stores.viewPortStore = new ViewPortStore();
    stores.megamenuStore = new MegamenuStore();

    // Load asynchronously
    Promise.all([stores.onlineHelpStore.init()]);

    return new RootStore(stores);
  }

  get cookieConsentStore() {
    return this.stores.cookieConsentStore;
  }

  get ssoStore() {
    return this.stores.ssoStore;
  }

  get messageStore() {
    return this.stores.messageStore;
  }

  get localeStore() {
    return this.stores.localeStore;
  }

  get translateStore() {
    return this.stores.translateStore;
  }

  get crmStore() {
    return this.stores.crmStore;
  }

  get featureStore() {
    return this.stores.featureStore;
  }

  get navigationStore() {
    return this.stores.navigationStore;
  }

  get myTechnicianStore() {
    return this.stores.myTechnicianStore;
  }

  get mandateCheckerStore() {
    return this.stores.mandateCheckerStore;
  }

  get onlineHelpStore() {
    return this.stores.onlineHelpStore;
  }

  get productUserStore() {
    return this.stores.productUserStore;
  }

  get viewPortStore() {
    return this.stores.viewPortStore;
  }

  get megamenuStore() {
    return this.stores.megamenuStore;
  }

  private constructor(public readonly stores: IRootStore) {}

  async dataReset() {
    this.crmStore.reset();
    this.ssoStore.reset();
  }
}

export default async (initData: RootStoreInitData): Promise<RootStore> => RootStore.createRootStore(initData);
