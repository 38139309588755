import React from 'react';
import { observer } from 'mobx-react';
import RootStoreContext from '../../root';
import IState from './IState';
import IProps from './IProps';
import { Notice } from 'telia-front-react';

@observer
class Required extends React.Component<IProps> {
  static defaultProps = {
    requiredDetails: ['customerId', 'type'],
  };
  static contextType = RootStoreContext;
  declare context: any;

  private disposer: Function;
  private isSufficientlyAuthenticated = () => !!this.getRequiredDetails();
  private getRequiredDetails = (): IState | undefined => {
    const { ssoStore } = this.context;

    const requiredDetails = ssoStore.dealer
      ? this.props.requiredDetails!.filter((detail) => detail !== 'contract')
      : this.props.requiredDetails!;

    if (!this.context.ssoStore.hasCustomer(...requiredDetails)) {
      return;
    }

    const { person, customer, mandates, dealer } = this.context.ssoStore as unknown as IState;
    return { person, customer, mandates, dealer };
  };

  componentDidMount() {
    this.disposer = this.context.ssoStore.ensureSignIn(this.isSufficientlyAuthenticated);
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const requiredDetails = this.getRequiredDetails();
    const { translateStore, mandateCheckerStore, productUserStore } = this.context;

    if (!requiredDetails || !productUserStore.hasLoadedPersonProductUsers) {
      return null;
    }

    const hasDiilAccountOnly = mandateCheckerStore.hasDiilAccountOnly;

    if (hasDiilAccountOnly) {
      return (
        <div className="container">
          <Notice>
            <p dangerouslySetInnerHTML={translateStore.translateHtml('my-services.dashboard.hidden')} />
          </Notice>
        </div>
      );
    }

    return this.props.render ? this.props.render(requiredDetails) : this.props.children;
  }
}

export default Required;
