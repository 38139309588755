import { API } from '@teliaee/sf.core';
import VehicleLocation from '../../common/types/booking/VehicleLocation';
import { AxiosResponse } from 'axios';
import { ApiUrl } from '../../common/constants/api';
import Address from '../../common/types/booking/Address';
import Booking from '../../common/types/booking/Booking';
import ExistingBooking from '../../common/types/booking/ExistingBooking';
import ScheduleItem from '../../common/types/booking/ScheduleItem';
import Schedules from '../../common/types/booking/Schedules';
import TechnicianBooking from '../../common/types/booking/TechnicianBooking';
import TechnicianBookingHash from '../../common/types/booking/TechnicianBookingHash';
import Location from '../../common/types/booking/Location';
import { URI } from 'uri-template-lite';
import TechnicianWorkOrder from '../../common/types/booking/TechnicianWorkOrder';

export class SeeMeApi {
  static getLastData = (hash: string): Promise<VehicleLocation> =>
    API.get(URI.expand(`${ApiUrl.SEEMEAPI_LASTDATA}`, { hash })).then((response: any) => response.data);
}

export class WorkforceApi {
  static getScheduleAndBookFirst = (
    productNumbers: string[],
    location: Address,
    language: string,
    existingBooking?: ExistingBooking
  ): Promise<Schedules> =>
    API.post<any, AxiosResponse<Schedules>>(ApiUrl.WORKFORCE_SCHEDULES_BOOKED, {
      productNumbers,
      location,
      language,
      existingBooking,
    }).then((response) => response.data);

  static updateBooking = (
    scheduleItem: ScheduleItem,
    productNumbers: string[],
    location: Address,
    language: string,
    bookingId: Number
  ): Promise<Booking> =>
    API.post<any, AxiosResponse<Booking>>(URI.expand(ApiUrl.WORKFORCE_BOOKING_ID, { bookingId }), {
      scheduleItem,
      productNumbers,
      location,
      language,
    }).then((response) => response.data);

  static confirmBooking = (
    scheduleItem: ScheduleItem,
    productNumbers: string[],
    location: Address,
    language: string,
    bookingId: Number
  ): Promise<Booking> =>
    API.put<any, AxiosResponse<Booking>>(URI.expand(ApiUrl.WORKFORCE_BOOKING_ID, { bookingId }), {
      scheduleItem,
      productNumbers,
      location,
      language,
    }).then((response) => response.data);

  static deleteBooking = (
    scheduleItem: ScheduleItem,
    productNumbers: string[],
    location: Address,
    language: string,
    bookingId: Number
  ): Promise<AxiosResponse> =>
    API.delete(URI.expand(ApiUrl.WORKFORCE_BOOKING_ID, { bookingId }), {
      data: {
        scheduleItem,
        productNumbers,
        location,
        language,
      },
    });

  static getBookingByHash = (hash: string): Promise<TechnicianBooking> =>
    API.get(URI.expand(ApiUrl.WORKFORCE_BOOKING_HASH, { hash })).then((response: any) => response.data);

  static createWorkOrder = (): Promise<TechnicianWorkOrder> => API.post(ApiUrl.WORKFORCE_WORKORDER).then((response: any) => response.data);

  static getSchedulesByHash = (hash: string, language: string): Promise<Schedules> =>
    API.get(URI.expand(ApiUrl.WORKFORCE_SCHEDULES, { hash }), {
      params: {
        language,
      },
    }).then((response: any) => response.data);

  static updateBookingByHash = (hash: string, scheduleItem: ScheduleItem, language: string): Promise<TechnicianBookingHash> =>
    API.post<any, AxiosResponse<TechnicianBookingHash>>(URI.expand(ApiUrl.WORKFORCE_BOOKING_HASH, { hash }), {
      scheduleItem,
      language,
    }).then((response) => response.data);

  static confirmBookingByHash = (
    hash: string,
    scheduleItem: ScheduleItem,
    language: string,
    location: Location,
    productSpecIds: string[],
    bookingId: Number,
    argosId: Number,
    bookingIdType: string,
    inCustomerPromise: String
  ): Promise<Booking> =>
    API.put<any, AxiosResponse<Booking>>(URI.expand(ApiUrl.WORKFORCE_BOOKING, { bookingId, hash }), {
      scheduleItem,
      productSpecIds,
      location,
      language,
      argosId,
      bookingIdType,
      inCustomerPromise,
    }).then((response) => response.data);

  static getPendingTechnicianSchedules = (productNumbers: string[]): Promise<TechnicianWorkOrder[]> =>
    API.get(ApiUrl.WORKFORCE_BOOKING_PENDING, {
      params: {
        productNumbers,
      },
    }).then((response: any) => response.data);

  static getEta = (hash: string): Promise<TechnicianBooking> =>
    API.get(URI.expand(ApiUrl.WORKFORCE_BOOKING_ETA, { hash })).then((response: any) => response.data);

  static cancelBookingByHash = (
    hash: string,
    scheduleItem: ScheduleItem,
    location: Location,
    cancellationReason: string
  ): Promise<AxiosResponse> =>
    API.post(URI.expand(ApiUrl.WORKFORCE_BOOKING_CANCEL, { hash }), {
      scheduleItem,
      location,
      cancellationReason,
    });
}
