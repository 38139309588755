export const PAGE_PATH_PARAM = 'page';
export const PRODUCTS_PARAM = 'productIds';
export const DEFAULT_MAP_CENTER_COORDINATES = { lat: 58.6945, lng: 25.9075 };
export const MAP_TECHNICIAN_LOGO_PATH =
  'M15.231 17.112c-5.045 2.912-8.981 3.668-11.368 1.556a19.984 19.984 0 0 1-2.603-3.86c1.94 2.114 6.29 1.368 12.089-1.404 4.806-2.297 8.44-5.815 ' +
  '8.614-8.611.024.242.037.495.037.759 0 1.89-.232 3.682-.64 5.346-.988 2.314-3.132 4.484-6.129 6.214zm-2.559-5.69C6.958 14.166 2.067 15.063.467 12.95.167 12.067 0 11.223 ' +
  '0 10.47 0 4.6 8.609 0 15.623 0c2.366 0 4.573.713 5.664 2.588.664 2.475-3.145 6.21-8.615 8.835zm-.461 8.319c4.789-1.926 7.37-4.662 8.575-6.89C18.65 19.02 14.137 23 10.84 ' +
  '23c-1.883 0-4.029-1.314-5.912-3.184l.017.014c1.217.969 3.419 1.463 7.265-.09z';
export const MAP_CLIENT_LOGO_PATH =
  'M30.359 13.34L17.676.658a2.377 2.377 0 0 0-3.353-.001L1.641 13.34c-1.845 1.844-1.22 3.353 1.389 3.353h.445v15.342h7.904v-9.809a1.188 1.188 0 0 1 ' +
  '1.186-1.186h6.869a1.188 1.188 0 0 1 1.186 1.186v9.809h7.904V16.693h.445c2.609 0 3.234-1.509 1.39-3.353z';
export const REDIRECT_TIME_MS = 3000;
export const BOOKING_STATE_ACTIVE = 'K';
