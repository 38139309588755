import React from 'react';
import { Modal, Separator, TextArea, ModalFooter, Grid, GridColumn, Button, Text } from 'telia-front-react';
import { IProps } from './IProps';

const Cancellation = (props: IProps) => {
  const { isOpen, onClose, isEmployee, cancellationReason, handleCancellationReasonChange, handleCancellation, translate } = props;

  return (
    <Modal isOpen={isOpen} size="6-col" onClose={onClose}>
      <h4 className="text-center">{translate('myTechnician.cancel.confirm')}</h4>
      <Separator color="invisible" spacing="xsmall" />
      <Text className="text-center">{translate('myTechnician.cancel.reason')}</Text>
      <TextArea
        id="cancellation-reason"
        label={translate('myTechnician.cancel.reason.label')}
        focusLabel={translate('myTechnician.cancel.reason.focus')}
        value={cancellationReason}
        onChange={handleCancellationReasonChange}
        isInvalid={false}
        helper={isEmployee ? { text: translate('myTechnician.cancel.employee.error'), type: 'error' } : undefined}
      />
      <ModalFooter>
        <Grid align={['between-xs']}>
          <GridColumn width={['xs-6']} align={['middle-xs']}>
            <Button text={translate('myTechnician.cancel.reject')} link iconLeft="#arrow-left" size="small" onClick={onClose} />
          </GridColumn>
          <GridColumn width={['xs']} align={['middle-xs']}>
            <Button
              type="expressive"
              text={translate('myTechnician.cancel.cancel2')}
              iconRight="#arrow-right"
              onClick={handleCancellation}
              disabled={isEmployee && !cancellationReason}
              id="myTechnician-cancellation-button"
            />
          </GridColumn>
        </Grid>
      </ModalFooter>
    </Modal>
  );
};

export default Cancellation;
