import { observer } from 'mobx-react';
import React from 'react';
import { Card, CardContent, Container, Grid, GridColumn, Image, LargeSearch, Separator, Text, TextField } from 'telia-front-react';
import Store from './store';
import RootStoreContext from '../../root';
import { RootStore } from '../../../common/root/init';
import { ExternalLink } from '../../../common/types/ExternalLink';

@observer
class NotFound extends React.Component {
  static contextType = RootStoreContext;
  declare context: RootStore;
  private store = new Store();

  private updateSearchFieldValue = (value: string) => this.store.setSearchFieldValue(value);

  private search = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { navigationStore } = this.context;
    const url = navigationStore.getExternalLink(ExternalLink.TELIAWEB, '/otsing?query=' + this.store.searchFieldValue);

    navigationStore.navigateTo(url);
  };

  render() {
    const { translateStore } = this.context;

    return (
      <Container>
        <Grid align={['middle-xs']} reverse={['sm']}>
          <GridColumn width={['sm-6', 'lg-5']}>
            <Text>
              <h1 dangerouslySetInnerHTML={translateStore.translateGlobalHtml('404.title')} />
              <p className="h4" dangerouslySetInnerHTML={translateStore.translateGlobalHtml('404.text')} />
            </Text>
          </GridColumn>
          <GridColumn width={['sm-6', 'lg-5']}>
            <Image srcset="https://www.telia.ee/images/pages/etc/404/404_retina.png" width={400} align="center" role="presentation" />
          </GridColumn>
        </Grid>
        <Card padding="medium">
          <CardContent>
            <Text className="text-center">
              <h2 dangerouslySetInnerHTML={translateStore.translateGlobalHtml('404.search')} />
              <Separator color="invisible" spacing="small" />
              <LargeSearch id="not-found-search-form" variant="light" formAttributes={{ onSubmit: this.search }}>
                <TextField
                  id="search"
                  value={this.store.searchFieldValue}
                  onChange={this.updateSearchFieldValue}
                  label={translateStore.translateGlobal('404.search.input')}
                />
              </LargeSearch>
            </Text>
          </CardContent>
        </Card>
      </Container>
    );
  }
}

export default NotFound;
