/* istanbul ignore file */
import React from 'react';
import IProps from './IProps';

class ErrorBoundary extends React.Component<IProps> {
  state = {
    hasError: false,
  };

  constructor(props: IProps) {
    super(props);
  }

  componentDidCatch(error: Error) {
    const { onError } = this.props;
    this.setState({ hasError: true });
    onError(true);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
