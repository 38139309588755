/* istanbul ignore file */

import { action, computed, observable, makeObservable } from 'mobx';
import { ErrorUtil } from '@teliaee/sf.core';
import { FeatureFlag } from './types';
import { readFeatureFlags } from './api';

export class FeatureStore {
  static ENABLE_CUSTOMER_ORDERS_VIEW = 'ENABLE_CUSTOMER_ORDERS_VIEW';

  @observable private readonly featureFlags: Map<string, boolean> = new Map();
  @observable initialized = false;

  constructor() {
    makeObservable(this);
  }

  async init() {
    await this.initFeatureFlags();
    this.setInitialized();
  }

  isEnabled(feature: string): boolean {
    return this.getFeatureFlag(feature) || false;
  }

  private getFeatureFlag(feature: string): boolean | undefined {
    if (!this.initialized) {
      ErrorUtil.pushError(new Error('Illegal state, FeatureStore should be initialized before Business Logic Stores'));
    }
    return this.featureFlags.get(feature);
  }

  @action
  private setInitialized = () => {
    this.initialized = true;
  };

  @action
  private setFeatureFlags = (featureFlags: FeatureFlag[]) => {
    featureFlags.forEach(({ feature, enabled }) => this.featureFlags.set(feature, enabled));
  };

  private async initFeatureFlags() {
    try {
      const { responsePromise } = readFeatureFlags();
      const featureFlags = (await responsePromise).data;
      this.setFeatureFlags(featureFlags || []);
    } catch (error: any) {
      throw error;
    }
  }

  @computed
  get enableCustomerOrdersView(): boolean {
    return this.isEnabled(FeatureStore.ENABLE_CUSTOMER_ORDERS_VIEW);
  }
}
